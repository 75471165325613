import React from "react";
import _ from "lodash";

// Models
import { sortItemByCate, getWarrantyCode } from "../../models/Orders";

import CustomDatePicker from "../global/CustomDatePicker";
import { FORMAT_APP_DATE } from "../../constants";
import { formatApiDate } from "../../functions/Date";

// Components
import { OrderFormOtherInfo } from "./OrderFormOtherInfo";
import { OrderFormPurchasePriceItems } from "./OrderFormPurchasePriceItems";
import ToggleSwitch from "../global/ToggleSwitch";

// Functions
import { Multiple } from "../../functions/Number";
import { parseExpRatioArr } from "../../functions/FeePurchaseCalculation";
// import { formatDate } from "../../functions/Date";
import { stringIsEmpty } from "../../functions/String";

export class OrderFormPurchasePrice extends React.Component {
  constructor(props) {
    super(props);
    let completed = props.order.order_flag.purchase_price_completed;
    this.state = {
      items: [],
      vendor: {},
      completed: completed,
      disabled: completed || props.disabled,
    };

    this.onChangeVendor = this.onChangeVendor.bind(this);
    this.onChangeContractPrice = this.onChangeContractPrice.bind(this);
  }

  componentDidMount() { }

  onChangeVendor(option, cate_id) { }

  onChangeContractPrice(e, id) {
    let { items } = this.state;
    let selected_item = items.filter((elm) => elm.value === id)[0];
    selected_item.contract_price = e.target.value;

    this.setState({ items: items });
  }

  displayItems() {
    let items = [];
    let list_index = 0;
    this.props.order.purchasing_order_items.sort(sortItemByCate).forEach((elm, index) => {
      let expense_group = {};

      if (elm.from_parent) return false;

      if (!elm.is_mbom) {
        if (elm.expense_ratio_arr) {
          expense_group = _.groupBy(elm.expense_ratio_arr, function (value) {
            return value;
          });
        } else {
          expense_group[elm.expense_ratio] = [elm.expense_ratio];
        }

        for (var key in expense_group) {
          let exp_ratio = parseExpRatioArr(key);
          let price_with_fee = 0
          let de_qty = elm.expense_ratio_arr ? expense_group[key].length : elm.qty;


          if (elm.is_parent) {
            const find_items_parent = this.props.order.purchasing_order_items.filter((e) => e.from_parent_item_id === elm.item_id && e.from_parent)
            find_items_parent.map((find_item) => {
              return price_with_fee = price_with_fee + find_item.contract_price * find_item.qty
            })

            price_with_fee = price_with_fee + exp_ratio
          } else {
            price_with_fee = elm.contract_price + exp_ratio;
          }



          items.push(
            <React.Fragment>
              {/* sm up */}
              <div className={`row mt-2 pl-2 d-none d-sm-flex ${list_index % 2 === 0 ? "" : "bg-aliceblue"}`}>
                <div className="col-p5 justify-content-center">{list_index + 1}</div>
                <div className="col-6">{elm.name}</div>
                <div className="col-2 p-0">{`${de_qty} ${elm.unit}`}</div>
                <div className="col text-right">{price_with_fee.toLocaleString()}</div>
                <div className="col text-right">{Multiple(price_with_fee, de_qty).toLocaleString()}</div>
              </div>

              {/* sm down */}
              <div className={`row mt-2 pl-2 d-sm-none ${index % 2 === 0 ? "" : "bg-aliceblue"}`}>
                <div className="col-1 pl-0 justify-content-center">{index + 1}</div>
                <div className="col-7 pl-0">
                  {elm.name} จำนวน {`${de_qty} ${elm.unit}`}
                </div>
                <div className="col text-right">{Multiple(price_with_fee, de_qty).toLocaleString()}</div>
              </div>
            </React.Fragment>
          );
        }
        list_index++;
      }
    });
    return items;
  }

  displayHeader() {
    return (
      <React.Fragment>
        <div className="row pl-2">
          <div className="col-p5 d-none d-sm-block text-center">ลำดับ</div>
          <div className="col-1 pl-0 d-sm-none text-center">#</div>
          <div className="col-7 pl-0 col-sm-6">สินค้า</div>
          <div className="col-2 d-none d-sm-block">จำนวน</div>
          <div className="col d-none d-sm-block text-right">ราคา</div>
          <div className="col text-right">ราคารวม</div>
        </div>
      </React.Fragment>
    );
  }

  onChangeApprovedDate(e) {
    const { order } = this.props;

    order.quotation.approved_date = formatApiDate(e);
    this.props.checkItemContract(order, "get", true);
  }

  render() {
    const { order } = this.props;
    const { disabled } = this.state;
    const disabled_contract = order.purchasing_order_items.reduce((pre, cur) => {
      return pre || !stringIsEmpty(getWarrantyCode(cur.warranty_codes));
    }, false);

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-md-6">
            <h5 className="pb-1 d-none d-md-flex" style={{ alignItems: "center", gap: 10 }}>
              <div>สรุปราคาขาซื้อ (ต่อหน่วย) - วันที่ดึงสัญญา</div>
              <CustomDatePicker className="form-control" dateFormat={FORMAT_APP_DATE} selected={order.quotation.approved_date} onChange={(date) => this.onChangeApprovedDate(date)} disabled={this.state.completed} />
            </h5>
            <h5 className="pb-1 d-md-none">
              สรุปราคาขาซื้อ (ต่อหน่วย)
              <br />
              <span className="font-size-15px mt-2">
                วันที่ดึงสัญญา <CustomDatePicker className="form-control" dateFormat={FORMAT_APP_DATE} selected={order.quotation.approved_date} onChange={(date) => this.onChangeApprovedDate(date)} disabled={this.state.completed} />
              </span>
            </h5>
          </div>
          <div className="col d-flex flex-row justify-content-end">
            <div className="mr-3">ราคาทุนสมบูรณ์แล้ว</div>
            <ToggleSwitch checked={this.state.completed} onChange={this.props.confirmPurchasePrices} />
          </div>
        </div>
        <div className="card-box px-3 py-2">{this.displayHeader()}</div>
        <div className="card-box px-3 py-2 mt-1">{this.displayItems()}</div>
        <h5 className="pb-1 mt-4">ปรับแต่งราคาขาซื้อ</h5>
        {disabled_contract ? (
          <div className="color-red">
            <b>คำเตือน</b>: ถ้ามีการออกรหัสทรัพย์สินแล้ว จะไม่สามารถเปลี่ยนสัญญาที่มีสินค้าที่ออกรหัสทรัพย์สินแล้วได้
          </div>
        ) : (
          ""
        )}
        <OrderFormPurchasePriceItems {...this.props} onChangeVendor={this.onChangeVendor} vendor={this.state.vendor} disabled={disabled} />

        {/* Other Info */}
        <OrderFormOtherInfo page_nb={this.props.page_nb} note={this.props.order.order_notes.filter((elm) => elm.page_nb === this.props.page_nb)[0]} onChangeNote={this.props.onChangeNote} disabled={this.props.disabled} key={this.props.resetKeyNote} />
        <div className="block-info-buffer-box"></div>
      </React.Fragment>
    );
  }
}
