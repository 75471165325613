import React from "react";
import DatePicker from "react-datepicker";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import _ from "lodash";
import { withRouter } from "react-router-dom";
// models
import {
  calTotalSales,
  initQuotationItemCategories,
  initDaystoInstallation,
  new_expense,
  initNewFee,
  initNewDiscount,
  validateQuotation,
  validateApproveQuo,
  deleteItem,
  genExpenseOnItemAdded,
  initExpense,
  isItemExpense,
  getCategoryTotalExpense,
  isDiscount,
  isItemDiscount,
  isDisabled,
  initWarrantyItem,
} from "../../models/Quotations";
import { itemCategoryName, getQtyCategory, getWarrantyExpirationDate } from "../../models/ItemCategories";
import { NEW_CUSTOMER } from "../../models/Customer";
import { validate_find_by_cate } from "../../models/Contracts";

import { QUO_STATES, API_RESOURCES, APP_TITLE_QUO, FORMAT_APP_DATE, ORDER_STATES, TRACKING_USER_TYPE } from "../../constants.js";

// components
import { QuotationFormDocuments } from "./QuotationFormDocuments";
import { QuotationFormItems } from "./QuotationFormItems";
import { QuotationPurchasePrices } from "./QuotationPurchasePrices";
import { QuotationFormIssuePo } from "./QuotationFormIssuePo";
import { QuotationManufacture } from "./QuotationManufacture";
import ModalConfirmation from "../global/ModalConfirmation";
import ProvinceSelect from "../global/ProvinceSelect";
import DistrictSelect from "../global/DistrictSelect";
import SubDistrictSelect from "../global/SubDistrictSelect";
import ClientStation from "../global/ClientStation";
import BuyerSelect from "../global/BuyerSelect";
import ErrorMessage from "../global/ErrorMessage";
import Spinner from "../global/Spinner";

// functions
import { generateID, uniqueKey } from "../../functions/Random.js";
import { objectIsEmpty, toUniqueArr, toSnakeCase, objectIndexOf } from "../../functions/Object";
import { solveFeeNew } from "../../functions/FeePurchaseCalculation";
import { formatDate, formatApiDate } from "../../functions/Date";
import { stringIsEmpty } from "../../functions/String";
import { CaretRightOutlined, CheckCircleOutlined, DeleteOutlined, ExclamationCircleFilled, ExclamationCircleOutlined, StarFilled, WarningOutlined } from "@ant-design/icons";
import { Collapse, Divider, Empty, Form, Input, Modal, Tooltip } from "antd";
import { Button } from "react-bootstrap";
import moment from "moment";

const QUO_TABS = {
  customer: 0,
  items: 1,
  documents: 2,
  purchase_prices: 3,
  manufacture: 4,
};
const FIELD_CLIENT_STATION = "client_station";
const FIELD_BUYER = "buyer";

class QuotationForm extends React.Component {
  constructor(props) {
    super(props);

    let new_cus = cloneDeep(NEW_CUSTOMER);
    let quo = {
      id: null,
      code: "",
      state: QUO_STATES.draft.name,
      client_station_id: null,
      client_station: new_cus,
      has_buyer: false,
      buyer: cloneDeep(NEW_CUSTOMER),
      quotation_items: [],
      quotation_item_categories: {},
      quotation_date: new Date(),
      approved_date: new Date(),
      approver_name: "",
      approver_title: "",
      note: "",
      cancel_note: "",
      user_id: props.current_user.id,
      owner: this.props.id === "new" ? `${this.props.current_user.firstname ? this.props.current_user.firstname : ""} ${this.props.current_user.lastname ? this.props.current_user.lastname : ""}` : "",
    };
    let quotation_id = props.id && props.id !== "new" ? parseInt(props.id) : null;

    this.state = {
      current_tab: QUO_TABS.info,
      selected_quotation_id: quotation_id,
      quotation: quo,
      selected_station: "",
      display_station: false,
      selected_buyer: "",
      display_buyer: false,
      disabled: false,
      isIssuingPO: false,
      is_canceling: false,
      is_rejecting: false,
      is_deleting: false,
      is_mounted: false,
      is_approving: false,
      reFilteringClientStation: uniqueKey(),
      item_options: [],
      selected_item_options: [],
      item_categories: [],
      is_loading: true,
      template: {},
      errors: {},
      errors_pprices: [],
      is_updated_soldto_client: false,
      is_updated_soldto_buyer: false,
      is_updated: false,
      is_leaving: false,
      randomKey: uniqueKey(),
      reset_pp_key: uniqueKey(),
      is_reset_contract_cate: false,
      load_cate: 0,
      cate_length: 0,

      is_add_to_template: false,
      is_show_add_template: false,

      selected_vendor_item: 0,
      selected_dc_item: 0,
      witholding_tax: 0,

      quotation_template_list: [],
      quotation_template_search_list: [],
      is_quotation_template_search: false,

      zones_data: [],
      zone_type: null,

      select_sub_item_list: [],
      filter_item_selected: [],

      check_duplicate: false,
      show_save_duplicate: false,
      duplicate_list: [],
      temp_stage_save: {},
    };

    this.setContractByItemCategory = this.setContractByItemCategory.bind(this);

    this.onChangeFormTab = this.onChangeFormTab.bind(this);
    this.onChangeCustomerStation = this.onChangeCustomerStation.bind(this);
    this.onChangeCustomerStationAddress = this.onChangeCustomerStationAddress.bind(this);
    this.onChangeCustomerProvince = this.onChangeCustomerProvince.bind(this);
    this.onChangeCustomerDistrict = this.onChangeCustomerDistrict.bind(this);
    this.onChangeCustomerSubDistrict = this.onChangeCustomerSubDistrict.bind(this);
    this.onClickSelectStation = this.onClickSelectStation.bind(this);
    this.onChangeBuyerProvince = this.onChangeBuyerProvince.bind(this);
    this.onChangeBuyerDistrict = this.onChangeBuyerDistrict.bind(this);
    this.onChangeBuyerSubDistrict = this.onChangeBuyerSubDistrict.bind(this);
    this.onClickSelectBuyer = this.onClickSelectBuyer.bind(this);

    this.onAddItem = this.onAddItem.bind(this);
    this.onAddItems = this.onAddItems.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
    this.onAddShippingCost = this.onAddShippingCost.bind(this);
    this.onDeleteShippingCost = this.onDeleteShippingCost.bind(this);
    this.onChangeQuotationDetails = this.onChangeQuotationDetails.bind(this);
    this.onChangeQuoHasBuyer = this.onChangeQuoHasBuyer.bind(this);
    this.onChangeQuoContractDate = this.onChangeQuoContractDate.bind(this);
    this.onReCalContractDate = this.onReCalContractDate.bind(this);
    this.onChangeItemDetails = this.onChangeItemDetails.bind(this);
    this.onChangeCategoryName = this.onChangeCategoryName.bind(this);
    this.onChangeShippingDetails = this.onChangeShippingDetails.bind(this);
    this.onChangeRemarkDetail = this.onChangeRemarkDetail.bind(this);
    this.onChangeQuoDate = this.onChangeQuoDate.bind(this);
    this.onChangeOwner = this.onChangeOwner.bind(this);
    this.handleDisplayStation = this.handleDisplayStation.bind(this);
    this.handleHideStation = this.handleHideStation.bind(this);
    this.handleDisplayBuyer = this.handleDisplayBuyer.bind(this);
    this.handleHideBuyer = this.handleHideBuyer.bind(this);

    this.onMatchSubItemList = this.onMatchSubItemList.bind(this);

    // Init/Reset
    this.onResetFee = this.onResetFee.bind(this);

    // Submit
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.onPreviewSubmit = this.onPreviewSubmit.bind(this);
    this.updateQuotationState = this.updateQuotationState.bind(this);
    this.updateInformedDate = this.updateInformedDate.bind(this);

    // IssuePO
    this.onHandleIssuePoModal = this.onHandleIssuePoModal.bind(this);
    this.onSubmitIssuePo = this.onSubmitIssuePo.bind(this);

    // Approve
    this.onHandleModalApprove = this.onHandleModalApprove.bind(this);

    // Cancel
    this.onHandleCancelModal = this.onHandleCancelModal.bind(this);
    this.onCancelItemsModal = this.onCancelItemsModal.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onHandleRejectModal = this.onHandleRejectModal.bind(this);
    this.onReject = this.onReject.bind(this);

    // delete
    this.onHandleModalDelete = this.onHandleModalDelete.bind(this);
    this.onDeleteQuotation = this.onDeleteQuotation.bind(this);

    // back
    this.backToQuotationIndex = this.backToQuotationIndex.bind(this);
    this.onHandleModalBack = this.onHandleModalBack.bind(this);
    this.onClickBack = this.onClickBack.bind(this);

    // contract
    this.onChangeContract = this.onChangeContract.bind(this);
    this.onResetContractPrice = this.onResetContractPrice.bind(this);

    // Manufacture
    this.onChangeCategoryInformedDate = this.onChangeCategoryInformedDate.bind(this);
    this.onGetWarrantyCodes = this.onGetWarrantyCodes.bind(this);
    this.onDeleteWarrantyCodes = this.onDeleteWarrantyCodes.bind(this);

    this.changeWithOutHoldingTax = this.changeWithOutHoldingTax.bind(this);

    this.displayModalAddToTemplate = this.displayModalAddToTemplate.bind(this);
    this.handleSubmitTemplate = this.handleSubmitTemplate.bind(this);
    this.onSelectExistingAddTemplate = this.onSelectExistingAddTemplate.bind(this);

    this.filterItemSelect = this.filterItemSelect.bind(this);
    this.setCheckDuplicateItem = this.setCheckDuplicateItem.bind(this)

    this.refTemplate = React.createRef();
    this.template_search_ref = React.createRef();
  }

  componentDidMount() {
    if (this.state.selected_quotation_id !== null) {
      this.getZone();
    }

    if (this.state.disabled === false) {
      this.getZone();
    }

    this.getQuotationTemplate(false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // reset purchase prices all items in categories

    if (!objectIsEmpty(this.state.is_reset_contract_cate)) {
      let { quotation } = this.state;
      let item_ids = [];
      let errors = [];
      quotation.quotation_items.forEach((elm) => item_ids.push(elm.item_id));
      this.state.is_reset_contract_cate.forEach((reset_cate) => {
        let new_error = this.setContractByItemCategory(
          {
            is_active: true,
            approved_date: formatApiDate(quotation.approved_date),
            item_category_id: reset_cate,
            province_id: quotation.client_station.address.province_id,
            item_ids: item_ids,
          },
          "update",
          true
        );

        if (new_error.length > 0) {
          errors = errors.concat(new_error);
        }
      });

      this.setState({
        errors_pprices: toUniqueArr(errors),
        is_reset_contract_cate: false,
      });
    }
  }

  getQuotation() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${this.state.selected_quotation_id}`)
      .then((response) => {
        let quotation = response.data;
        let { item_options } = this.state;
        let current_installation_date = {};
        let selected_vendor_item = 0;
        let selected_dc_item = 0;
        let set_select_sub_item_list = [];

        this.setState({ witholding_tax: quotation.witholding_tax });
        quotation.quotation_item_categories = initQuotationItemCategories(quotation.quotation_item_categories);

        Object.keys(quotation.quotation_item_categories).forEach((key) => {
          const cate = quotation.quotation_item_categories[key];
          const itemReduce = quotation.quotation_items.filter((item) => item.item_category_id === cate.item_category_id && !item.from_parent);

          if (cate.item_category.dc_id === 0) {
            selected_vendor_item = selected_vendor_item + itemReduce.length;
          } else {
            selected_dc_item = selected_dc_item + itemReduce.length;
          }
        });

        this.getSelectedItemOptions(quotation);
        quotation.quotation_items = initDaystoInstallation(quotation);

        // set selected item option's flags to true if item options still can be selected
        if (this.state.disabled === false) {
          quotation.quotation_items = quotation.quotation_items.map((quo_item) => {
            if (quo_item.from_parent) quo_item["parent_item_id"] = quo_item.from_parent_item_id;
            else if (quo_item.is_parent) {
              quo_item.price = 0;
              const find_parent_item = quotation.quotation_items.filter(({ from_parent_item_id }) => from_parent_item_id === quo_item.item_id);
              find_parent_item?.forEach((f_item) => {
                quo_item.price = (f_item.price * f_item.qty) + quo_item.price;
              });

              quo_item["parent_price"] = quo_item.price;
            }

            quo_item = initWarrantyItem(quo_item, quotation.quotation_item_categories);
            item_options = this.setSelectedFlagOnItemOption(quo_item.item_id, true);

            this.filterItemSelect(item_options);

            current_installation_date[quo_item.id] = quo_item.installation_date;
            return quo_item;
          });

          quotation.quotation_items.forEach((quo) => {
            if (quo.from_parent) set_select_sub_item_list.push({ parent_item_id: quo.from_parent_item_id, sub_item_id: quo.item_id });
          });
        }

        this.filterItemSelect();

        if (!quotation.has_buyer) {
          quotation.buyer = cloneDeep(NEW_CUSTOMER);
        }

        let witholding_tax = quotation.witholding_tax
        if (witholding_tax === 0) {
          quotation.quotation_items.forEach((item) => {
            if (item.wht) witholding_tax = witholding_tax + item.wht
          })

          quotation.witholding_tax = witholding_tax
        }

        let zone_type = null;

        if (this.state.zones_data.length > 0) {
          this.state.zones_data.forEach((zone) => {
            if (zone.name !== "ทั่วประเทศ") {
              const filter = zone.province_ids.find((_id) => _id === quotation.client_station.address.province_id);
              if (filter) {
                zone_type = zone;
              }
            }
          });
        }

        this.setState({ zone_type: zone_type });

        this.checkItemContract(quotation, "get", true);

        this.onMatchSubItemList(set_select_sub_item_list);

        this.filterItemSelect(item_options);
        //if the quo got cloned from other quo -> get whole cloned_from_quo and check wheater got cloned_to_edit
        if (quotation.state === QUO_STATES.cloned_to_edit.name) {
          axios
            .get(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.cloned_from_id}`)
            .then((response) => {
              let clone_from_quo = response.data;

              this.setState({
                select_sub_item_list: set_select_sub_item_list,
                clone_from_quo: clone_from_quo,
                quotation: quotation,
                item_options: item_options,
                selected_station: quotation.client_station_id,
                display_station: quotation.client_station_id ? true : false,
                selected_buyer: quotation.has_buyer ? quotation.buyer_id : null,
                display_buyer: quotation.buyer_id ? true : false,
                disabled: isDisabled(quotation),
                selected_dc_item: selected_dc_item,
                selected_vendor_item: selected_vendor_item,
                is_mounted: true,
                is_loading: false,
                randomKey: uniqueKey(),
              });
            })
            .catch((error) => {
              this.setState({
                is_loading: false,
              });
            });
        } else {
          this.setState({
            select_sub_item_list: set_select_sub_item_list,
            quotation: quotation,
            witholding_tax: witholding_tax,
            current_installation_date: current_installation_date,
            item_options: item_options,
            selected_station: quotation.client_station_id,
            display_station: quotation.client_station_id ? true : false,
            selected_buyer: quotation.has_buyer ? quotation.buyer_id : null,
            display_buyer: quotation.buyer_id ? true : false,
            disabled: isDisabled(quotation),
            selected_dc_item: selected_dc_item,
            selected_vendor_item: selected_vendor_item,
            is_loading: false,
            is_mounted: true,
            randomKey: uniqueKey(),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          is_loading: false,
        });
      })
      .then(() => { });

    this.setState({ is_loading: true });
  }

  changeWithOutHoldingTax(e) {
    this.setState({ witholding_tax: e.target.value });
  }

  checkItemContract(new_quotation, type = "none", isDefaultLoad = false) {
    let item_ids = [];
    let reset_cates = Object.keys(new_quotation.quotation_item_categories);
    new_quotation.quotation_items.forEach((elm) => item_ids.push(elm.item_id));

    if (reset_cates.length === 0) this.setState({ is_loading: false });

    this.setState({ cate_length: reset_cates.length });

    if (new_quotation.quotation_items) {
      reset_cates.forEach((reset_cate) => {
        let contract_id = new_quotation.quotation_item_categories[reset_cate].contract_id;

        this.setContractByItemCategory(
          {
            is_active: true,
            approved_date: formatApiDate(new_quotation.approved_date),
            item_category_id: reset_cate,
            id: contract_id,
            // province_id: new_quotation.client_station.address.province_id,
            item_ids: item_ids,
          },
          type,
          isDefaultLoad
        );
      });
    }
  }

  getZone() {
    axios.get(`${process.env.REACT_APP_API_URL}/zones`).then((response) => {
      if (response.status === 200) {
        this.setState({ zones_data: response.data });
        this.getItemOptions();
      }
    });
  }

  getItemOptions() {
    let params = {
      sort: JSON.stringify(["item_category_id", "ASC"]),
      filter: { is_active: true },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/item_options`, {
        params: params,
      })
      .then((response) => {
        let options = response.data.map((e) => {
          let cate_name = "";
          if (e.item_category) {
            cate_name = itemCategoryName(e.item_category);
          }
          e.label = `(${e.item_category.dc_id === 1 ? `DC` : `Vendor`}) [${cate_name}] ${e.name}`;
          e.value = e.code;
          e.is_selected = false;
          return e;
        });

        if (this.state.selected_quotation_id === null) {
          this.setState({
            is_loading: false,
          });
        }

        this.setState({
          item_options: options,
          is_mounted: true,
        });

        if (this?.props?.id !== "new") this.getQuotation();
        else this.filterItemSelect();

        this.getItemCategories();
      })
      .catch(() => {
        this.setState({
          is_loading: false,
        });
      })
      .then(function () {
        // always executed
      });
  }

  getSelectedItemOptions(quotation, isResetZone = false) {
    if (quotation.quotation_items.length !== 0) {
      let item_ids = quotation.quotation_items.map((item) => {
        return item.item_id;
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/items?filter=%7B"id": [${item_ids}]%7D`)
        .then((response) => {
          this.setState({
            selected_item_options: response.data,
          });

          if (isResetZone) {
            quotation.quotation_items.forEach((item, index) => {
              const findNewItem = response.data.find((data) => data.id === item.item_id);

              if (quotation.quotation_items[index].is_parent) quotation.quotation_items[index].price = quotation.quotation_items[index].parent_price;
              else quotation.quotation_items[index].price = findNewItem.price;

              quotation.quotation_items[index].regional_price = findNewItem.regional_price;
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  getItemCategories() {
    let params = {
      filter: { is_active: true },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/item_categories`, {
        params: params,
      })
      .then((response) => {
        this.setState({
          item_categories: response.data,
        });
        this.getTemplate();
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  getTemplate() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/templates/quotation`)
      .then((response) => {
        let { quotation } = this.state;
        let template = response.data;
        if (quotation.id === null) {
          quotation.approver_name = template.quo_approver_name;
          quotation.approver_title = template.quo_approver_title;
          quotation.note = template.quotation_remark;
        }
        this.setState({
          template: response.data,
          quotation: quotation,
          is_loading: false,
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  getDistanceFromMatrix(dst_province_id) {
    // src_province_id = 1 (Bangkok)
    axios
      .get(`${process.env.REACT_APP_API_URL}/distance_matrixes?filter=%7B"src_province_id":1,"dst_province_id":${dst_province_id}%7D`)
      .then((response) => {
        let { quotation } = this.state;

        if (response.data.length > 0) {
          // Update distance
          quotation.client_station.distance = response.data[0].distance;

          // Recalculate Fees
          let categories = quotation.quotation_item_categories;
          for (var key in categories) {
            this.reCalExpense(quotation.quotation_items, categories[key], quotation.client_station.distance);
          }
          this.setState({
            quotation: quotation,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  setContractByItemCategory(filter_params, type = "none", isDefaultLoad = false, isChangeContract = false) {
    let errors = validate_find_by_cate(filter_params);
    // if (objectIsEmpty(errors)) {
    let filters = { for_mr: false };
    Object.keys(filter_params).forEach((key) => {
      filters[key] = filter_params[key];
    });

    this.setState({ is_loading: true, load_cate: 0 });

    let params = {
      sort: JSON.stringify(["created_at", "DESC"]),
      filter: filters,
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/contract_by_cate`, {
        params: params,
      })
      .then((response) => {
        let { quotation, load_cate, cate_length } = this.state;

        if (response.data.id) {
          let contract = response.data;

          let contract_cate = contract.contract_item_categories[0];
          let all_expenses = contract_cate.contract_item_category_fees;


          contract_cate.contract_items.forEach((elm) => {
            all_expenses = all_expenses.concat(elm.contract_item_fees, elm.contract_item_discounts);
          });

          if (type === "update") {
            quotation.quotation_item_categories[filter_params.item_category_id].contract_id = contract.id;
          }

          // Update expenses
          quotation = this.updateExpenseContractPrice(quotation, filter_params.item_category_id, all_expenses, isChangeContract);

          // Update items
          quotation.quotation_items
            .filter((quo_item) => quo_item.item_category_id == filter_params.item_category_id)
            .forEach((item) => {
              let contract_item = contract_cate.contract_items.filter((elm) => elm.item_id === item.item_id)[0];

              if (contract_item) {
                if (contract_item.price === 0) {
                  if (type === "update") {
                    item.contract_price = item.contract_price ? item.contract_price : contract_item.price;
                  }
                } else {
                  if (isDefaultLoad) {
                    item.contract_price = item.contract_price ? item.contract_price : contract_item.price;
                  } else {
                    item.contract_price = contract_item.price;
                  }
                }
                item.contract_item_id = contract_item.id;
                item.is_active = contract_item.is_active;
              } else {
                item.contract_price = 0;
                item.is_active = false;
              }
            });

          this.setState({ quotation: quotation, load_cate: load_cate + 1 });
        } else {
          quotation.quotation_items
            .filter((elm) => elm.item_category_id === filter_params.item_category_id)
            .forEach((item) => {
              item.contract_price = 0;
              item.is_active = false;
            });

          this.setState({ load_cate: load_cate + 1 });
        }

        if (cate_length === load_cate + 1 && type !== "update") {
          this.setState({ is_loading: false });
          if (this.state.current_tab === 3 && type !== "save") {
            this.errorAlert(quotation.quotation_items);
          }
        }

        if (!this.state.selected_quotation_id || type === "update") {
          this.setState({ is_loading: false });
          if (this.state.current_tab === 3) {
            this.errorAlert(quotation.quotation_items);
          }
        }

        this.setState({ quotation: quotation });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ is_loading: false });
      })
      .then(() => { });
    // }

    return errors;
  }

  errorAlert(items) {
    let select_error = this.checkContrackStatus(items);
    if (select_error) {
      alert(select_error);
      this.setState({ is_loading: false });
      return;
    }
  }

  checkContrackStatus(items) {
    let errors = "";
    items.forEach((item) => {
      if (item.item_category.dc_id === 0 && !item.is_parent) {
        if (item.contract_price === 0 && item.is_active) {
          errors = errors + `- ราคาขาขายของ ${item.name} เป็น 0 บาท\n`;
        } else if (item.contract_price === 0 && item.is_active === false) {
          errors = errors + `- ราคาขาขายของ ${item.name} เป็น 0 บาท และไม่พบสินค้านี้ในสัญญาที่เลือก\n`;
        } else if (item.contract_price !== 0 && item.is_active === false) {
          errors = errors + `- ไม่พบสินค้า ${item.name} นี้ในสัญญาที่เลือก\n`;
        }
      }
    });

    return errors;
  }

  setItemContractPriceByContractId(contract_id, item_category_id, item_id, quo_item_id) {
    let params = {
      // sort: JSON.stringify(["created_at","DESC"]),
      filter: {
        item_id: item_id,
        contract_id: contract_id,
        // is_active: true
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/contract_item_by_contract_id`, {
        params: params,
      })
      .then((response) => {
        if (response.data.id) {
          let { quotation } = this.state;
          let { quotation_items } = quotation;
          let contract_item = response.data;
          let all_expenses = [];
          all_expenses = all_expenses.concat(contract_item.contract_item_fees, contract_item.contract_item_discounts);

          // Set contract price
          if (quotation_items.filter((elm) => elm.id === quo_item_id)[0]) {
            quotation_items.filter((elm) => elm.id === quo_item_id)[0].contract_price = contract_item.price;
            quotation_items.filter((elm) => elm.id === quo_item_id)[0].contract_item_id = contract_item.id;
          }

          // Update expenses
          quotation = this.updateExpenseContractPrice(quotation, item_category_id, all_expenses);

          this.setState({ quotation: quotation });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  updateExpenseContractPrice(quotation, item_category_id, all_expenses, isChangeContract = false) {
    if (quotation.quotation_item_categories[item_category_id]) {
      quotation.quotation_item_categories[item_category_id].item_category_expenses.forEach((exp, index) => {
        if (exp.resource_type_id) {
          let contract_exp = all_expenses.filter((elm) => elm[`${toSnakeCase(exp.resource_type)}_id`] === exp.resource_type_id)[0];

          if (contract_exp) {
            quotation.quotation_item_categories[item_category_id].item_category_expenses[index].contract_price = isChangeContract
              ? contract_exp.price
              : quotation.quotation_item_categories[item_category_id].item_category_expenses[index].contract_price
                ? quotation.quotation_item_categories[item_category_id].item_category_expenses[index].contract_price
                : contract_exp.price;
          } else {
            console.warn("======== Not found contract_exp for %s", exp.name);
          }
        }
      });
    }

    return quotation;
  }

  onMatchSubItemList(sub_items) {
    const remove_duplicate_items = sub_items.filter((item, i_index) => sub_items.findIndex((s_item) => s_item.parent_item_id === item.parent_item_id && s_item.sub_item_id === item.sub_item_id) === i_index);
    this.setState({ select_sub_item_list: remove_duplicate_items });

    return remove_duplicate_items;
  }

  reCalExpense(items, item_category, client_station_distance) {
    let { client_station } = this.state.quotation;
    let target_items = items.filter((item) => item.item_category_id === item_category.item_category_id);
    let sum_qty = target_items.reduce((sum, item) => (sum += parseInt(item.qty)), 0);
    let distance = client_station_distance;
    if (!client_station_distance) {
      distance = client_station.distance;
    }

    // recal fee qty (start)
    item_category.item_category_expenses
      .filter((expense) => !isDiscount(expense))
      .forEach((elm, index) => {
        if (elm.is_new !== true) {

          let input_qty = sum_qty;
          if (isItemExpense(elm.resource_type)) {
            let target_item = items.filter((item) => item.item_id === elm.resource_id)[0];
            input_qty = target_item?.qty || 0;
          }
          let caled_qtys = getQtyCategory(target_items, elm, distance, input_qty);

          if (!elm.is_custom_qty) {
            elm.qty = caled_qtys.qty;
          }
          elm.item_qty = caled_qtys.item_qty;
        }
      });
    // recal fee qty (end)

    // recal item discount qty (start)
    let discountGroupByName = _.groupBy(item_category.item_category_expenses, function (elm) {
      if (!elm.is_custom_qty && !elm.is_new && isItemDiscount(elm)) {
        return elm.name;
      } else {
        return "";
      }
    });

    Object.keys(discountGroupByName).forEach((key) => {
      if (key !== "") {
        let all_qty_in_group = 0;
        discountGroupByName[key].forEach((elm, index) => {
          all_qty_in_group += parseInt(items.filter((item) => item.item_id === elm.resource_id)[0].qty);
          if (index !== 0) {
            elm.item_qty = 0;
          }
        });

        let elm = discountGroupByName[key][0];
        let input_qty = sum_qty;
        if (isItemExpense(elm.resource_type)) {
          input_qty = all_qty_in_group;
        }
        let caled_qtys = getQtyCategory(target_items, elm, distance, input_qty);
        if (!elm.is_custom_qty) {
          elm.qty = caled_qtys.qty;
        }
        elm.item_qty = caled_qtys.item_qty;
      }
    });
    // recal item discount qty (end)

    // set qty to 0 if there is a match discount with a fee (start)
    let GroupByName = _.groupBy(item_category.item_category_expenses, function (elm) {
      return elm.name;
    });
    Object.keys(discountGroupByName).forEach((key) => {
      if (key !== "" && GroupByName[key].length >= 2) {
        let group_sum = getCategoryTotalExpense(GroupByName[key]);
        if (group_sum >= 0) {
          let discount = GroupByName[key].filter((elm) => isDiscount(elm))[0];
          let fee = GroupByName[key].filter((elm) => !isDiscount(elm))[0];
          if (fee && discount) {
            //======= Method 3 per qty ========
            if (fee.method_type === 3) {
              if (!fee.is_custom_qty) {
                fee.qty = fee.qty - discount.qty;
              }
              discount.qty = 0;
            }
            //======= Method 5 per km * qty ========
            if (fee.method_type === 5) {
              fee.item_qty = fee.item_qty - discount.item_qty;
              discount.item_qty = 0;
            }
          }
        }
      }
    });
    // set qty to 0 if there is a match discount with a fee (end)

    // recal item's retio
    let sum_expense = getCategoryTotalExpense(item_category.item_category_expenses);

    console.log('sum_expense', sum_expense)

    let item_qty_arr = [];
    let item_price_arr = [];

    let apply_items = []

    target_items.forEach((item) => {
      if (item.from_parent) return
      apply_items.push(item)
      item_qty_arr.push(item.qty);
      item_price_arr.push(item.is_parent ? item.parent_price : item.price || 0);
    });

    let suggest_fee = solveFeeNew(sum_expense, item_qty_arr, item_price_arr);
    suggest_fee[2].forEach((value, index) => {
      console.log('value', value, apply_items[index])
      if (apply_items[index]) apply_items[index]["expense_ratio"] = value || 0;
    });

    apply_items.forEach((item) => {
      const find_target_index = target_items.findIndex((target) => target.id === item.id)
      if (target_items[find_target_index]) {
        target_items[find_target_index]["expense_ratio"] = item.expense_ratio || 0;
        if (item.is_parent)
          target_items[find_target_index]["price"] = item.parent_price || 0;
      }
    })
  }

  genQuotationItemCategory(item_category_id) {
    let { item_categories } = this.state;
    let id = parseInt(item_category_id);

    // clone item category
    const cate_index = item_categories.findIndex((cate) => cate.id === id);
    let new_quo_item_cate = cloneDeep(item_categories[cate_index]);
    new_quo_item_cate["item_category_expenses"] = [];
    new_quo_item_cate.item_category_id = id;
    new_quo_item_cate.item_category = {
      warranty_type: item_categories[cate_index].warranty_type,
      warranty_value: item_categories[cate_index].warranty_value,
    };
    new_quo_item_cate.item_category_fees
      .filter((elm) => elm.is_active)
      .forEach((expense, index) => {
        new_quo_item_cate.item_category_expenses.push(initExpense(id, expense, index + 1, "ItemCategoryFee"));
      });
    delete new_quo_item_cate.item_category_fees;

    // push new fee to the cloned category
    new_quo_item_cate.item_category_expenses.push(initNewFee(id));
    // push new discount to the cloned category
    new_quo_item_cate.item_category_expenses.push(initNewDiscount(id));
    return new_quo_item_cate;
  }

  onResetFee(item_category_id) {
    let { quotation, selected_item_options } = this.state;
    delete quotation.quotation_item_categories[item_category_id];
    quotation.quotation_item_categories[item_category_id] = this.genQuotationItemCategory(item_category_id);

    // Add item fee and discount
    quotation.quotation_items
      .filter((elm) => elm.item_category_id == item_category_id)
      .forEach((item) => {
        let item_option = selected_item_options.filter((elm) => elm.id === item.item_id)[0];
        if (item_option !== undefined) {
          quotation.quotation_item_categories[item_category_id] = genExpenseOnItemAdded(quotation.quotation_item_categories[item_category_id], item_option, item.qty);
        }
      });

    // Recalculate expenses
    this.reCalExpense(quotation.quotation_items, quotation.quotation_item_categories[item_category_id]);

    this.setState({ quotation: quotation });
  }

  checkSaveError(items) {
    let { quotation } = this.state;
    let errors = "";

    items.forEach((item) => {
      const isDc = item.item_category ? item.item_category.dc_id : quotation.quotation_item_categories[item.item_category_id].item_category.dc_id;

      if (parseInt(isDc) === 0 && !item.is_parent) {
        if (item.contract_price === 0 && !item.is_active) {
          errors = errors + `- ราคาขาขายของ ${item.name} เป็น 0 บาท และไม่พบสินค้านี้ในสัญญาที่เลือก\n`;
        } else if (item.contract_price !== 0 && !item.is_active) {
          errors = errors + `- ไม่พบสินค้า ${item.name} นี้ในสัญญาที่เลือก\n`;
        }
      }
    });

    return errors;
  }

  postNewQuotation(newState) {
    let { quotation, is_leaving } = this.state;

    const error = this.checkSaveError(quotation.quotation_items);

    if (error !== "") {
      alert(error);
      return;
    }

    // quotation.total_sales = calTotalSales(quotation)
    quotation.user_id = this.props.current_user.id;

    if (this.state.witholding_tax && this.state.witholding_tax !== 0) {
      quotation.witholding_tax = this.state.witholding_tax;
    } else {
      quotation.witholding_tax = 0;
    }

    quotation.quotation_items.forEach((quo_item, index) => {
      const isDc = quotation.quotation_item_categories[quo_item.item_category_id].dc_id === 1;
      if (isDc && this.state.zone_type) {
        quotation.quotation_items[index].price = this.state.zone_type.name === "ส่วนภูมิภาค" ? (quo_item.regional_price ? quo_item.regional_price : quo_item.price) : quo_item.price;
      }
    });

    quotation.total_sales = calTotalSales(quotation);

    if (newState !== undefined) {
      quotation.state = newState;
      if (newState === QUO_STATES.submitted.name) {
        quotation.submitted_date = formatApiDate(new Date());
      }
    }

    // quotation.quotation_date = moment(this.state.quotation.quotation_date).format("YYYY-MM-DD")

    axios
      .post(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}`, {
        quotation: this.state.quotation,
        client_station: this.state.quotation.client_station.sold_to_code ? this.state.quotation.client_station.sold_to_code : null,
        buyer: this.state.quotation.buyer.sold_to_code ? this.state.quotation.buyer.sold_to_code : null,
      })
      .then((response) => {
        if (response.data.status === 400) {
          alert(response.data.message);
          this.setState({ is_loading: false });
        } else {
          if (is_leaving) {
            window.location.href = `/${API_RESOURCES.quotation}`;
          } else {
            window.location.href = `/${API_RESOURCES.quotation}/${response.data.id}`;
            //this.updateFromAfterSave(response.data)
          }
        }
      })
      .catch(() => {
        this.setState({ is_loading: false });
      });
    this.setState({ is_loading: true });
  }

  createUserTracking(value, response) {
    if (value.client_station.sold_to_code) {
      const client_params = {
        username: value.client_station.sold_to_code,
        password: value.client_station.sold_to_code,
        role: 0,
        user_type: "client_station",
        user_id: parseInt(response.data.client_station.id),
        name: response.data.client_station.name,
        address_id: parseInt(response.data.client_station.address_id),
      };

      axios.post(`${process.env.REACT_APP_API_URL}/create_tracking_user`, client_params).then((response) => { });
    }

    if (value.buyer.sold_to_code) {
      const buyer_params = {
        username: value.buyer.sold_to_code,
        password: value.buyer.sold_to_code,
        role: 0,
        user_type: "buyer",
        user_id: parseInt(response.data.buyer.id),
        name: response.data.buyer.name,
        address_id: parseInt(response.data.buyer.address_id),
      };

      axios.post(`${process.env.REACT_APP_API_URL}/create_tracking_user`, buyer_params).then((response) => { });
    }
  }

  createTrackingUser(user) {
    let new_user = "";
    let user_type = "";
    if (user == "client_station") {
      new_user = this.state.quotation.client_station;
      user_type = TRACKING_USER_TYPE.client_stations;
    } else if (user == "buyer") {
      new_user = this.state.quotation.buyer;
      user_type = TRACKING_USER_TYPE.buyers;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/tracking/user/${new_user.sold_to_code}`)
      .then((response) => {
        let hasTracking = response.data.data.exist;

        if (!hasTracking) {
          let params = {
            username: new_user.sold_to_code,
            password: new_user.sold_to_code,
            role: user_type.value,
            user_type: user_type.name,
            user_id: new_user.id,
            name: new_user.name,
            address_id: new_user.address_id ? new_user.address_id : null,
          };

          axios
            .post(`${process.env.REACT_APP_API_URL}/tracking/user`, params)
            .then((response_user) => {
              console.log(`Client station successfully created on P4S and Tracking!`);
            })
            .catch((error) => {
              console.log(error);
              alert(error.message);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  putQuotation(newState) {
    let { quotation, is_leaving } = this.state;

    const error = this.checkSaveError(quotation.quotation_items);

    if (error !== "") {
      alert(error);
      return;
    }

    quotation.user_id = this.props.current_user.id;

    if (this.state.witholding_tax && this.state.witholding_tax !== 0) {
      quotation.witholding_tax = this.state.witholding_tax;
    } else {
      quotation.witholding_tax = 0;
    }

    quotation.quotation_items.forEach((quo_item, index) => {
      const isDc = quotation.quotation_item_categories[quo_item.item_category_id].item_category.dc_id === 1;
      if (isDc && this.state.zone_type) {
        quotation.quotation_items[index].price = this.state.zone_type.name === "ส่วนภูมิภาค" ? (quo_item.regional_price ? quo_item.regional_price : quo_item.price) : quo_item.price;
      }
    });

    quotation.total_sales = calTotalSales(quotation);

    let errors = validateApproveQuo(quotation, newState);
    if (objectIsEmpty(errors)) {
      if (newState !== undefined) {
        quotation.state = newState;
        if (newState === QUO_STATES.submitted.name) {
          quotation.submitted_date = formatApiDate(new Date());
        }
        //update Approved state and approved_state_date and all item categories' informed_date
        // and auto calculate installation_date
        else if (newState === QUO_STATES.approved.name) {
          quotation.approved_state_date = formatApiDate(new Date());
        }
      }
      axios
        .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}`, {
          quotation: this.state.quotation,
          client_station: this.state.quotation.client_station.sold_to_code ? this.state.quotation.client_station.sold_to_code : null,
          buyer: this.state.quotation.buyer.sold_to_code ? this.state.quotation.buyer.sold_to_code : null,
        })
        .then((response) => {
          if (response.data.status === 400) {
            alert(response.data.message);
            this.setState({ is_loading: false });
          } else {
            if (is_leaving) {
              window.location.href = `/${API_RESOURCES.quotation}`;
            } else {
              this.updateFromAfterSave(response.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ is_loading: false });
        });
      this.setState({ is_loading: true });
    } else {
      if (errors["no_contracts"]) {
        alert(errors["no_contracts"]);
      }
    }
  }

  updateFromAfterSaveNoAlert(new_quo) {
    let { selected_buyer } = this.state;
    new_quo.quotation_item_categories = initQuotationItemCategories(new_quo.quotation_item_categories);
    if (!new_quo.has_buyer) {
      new_quo.buyer = cloneDeep(NEW_CUSTOMER);
    }
    this.setState({
      quotation: new_quo,
      is_loading: false,
      is_updated: false,
      is_canceling: false,
      randomKey: uniqueKey(),
      selected_station: new_quo.client_station.id,
      selected_buyer: new_quo.buyer ? new_quo.buyer.id : selected_buyer,
      disabled: isDisabled(new_quo),
    });
  }

  updateFromAfterSave(new_quo) {
    let { selected_buyer } = this.state;
    new_quo.quotation_item_categories = initQuotationItemCategories(new_quo.quotation_item_categories);
    new_quo.quotation_items = initDaystoInstallation(new_quo);
    let current_installation_date = {};
    new_quo.quotation_items.forEach((quo_item, q_index) => {
      current_installation_date[quo_item.id] = quo_item.installation_date;
      if (new_quo.quotation_items[q_index].is_parent) new_quo.quotation_items[q_index].parent_price = new_quo.quotation_items[q_index].price;
    });

    if (!new_quo.has_buyer) {
      new_quo.buyer = cloneDeep(NEW_CUSTOMER);
    }

    this.setState({
      quotation: new_quo,
      current_installation_date: current_installation_date,
      is_loading: false,
      is_updated: false,
      is_canceling: false,
      randomKey: uniqueKey(),
      selected_station: new_quo.client_station.id,
      selected_buyer: new_quo.buyer ? new_quo.buyer.id : selected_buyer,
      disabled: isDisabled(new_quo),
      load_cate: 0,
    });

    this.checkItemContract(new_quo, "update", true);

    alert("Quotation Saved!");
  }

  updateQuotationState(state) {
    let { quotation } = this.state;
    quotation.state = state;
    quotation.user_id = this.props.current_user.id;
    axios
      .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}/update_state`, {
        state: quotation.state,
        cancel_note: quotation.cancel_note,
      })
      .then(() => {
        window.location.href = `/${API_RESOURCES.quotation}/${quotation.id}`;
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false });
      });

    this.setState({ is_loading: true });
  }

  // Phase 3: automatic update Informed Date to date of approved state
  // ===Start===
  updateAllInformedDate() {
    let { quotation } = this.state;

    Object.keys(quotation.quotation_item_categories).forEach((key) => {
      quotation.quotation_item_categories[key].informed_date = formatApiDate(new Date());
    });

    this.setState({ quotation: quotation });
  }

  updateNewInformedDate(category_key, type) {
    let { quotation } = this.state;

    quotation.quotation_item_categories[category_key].informed_date = type === "cancel" ? null : formatApiDate(new Date());

    this.setState({ quotation: quotation });
  }

  updateInstallationDate(category_key, type) {
    let { quotation } = this.state;
    let { quotation_items, quotation_item_categories } = quotation;
    quotation_items.forEach((elm) => {
      if (parseInt(category_key) === parseInt(elm.item_category_id)) {
        let inst_date = new Date();
        let nb_days_to_complete = quotation_item_categories[elm.item_category_id].contract.nb_days_to_complete ? quotation_item_categories[elm.item_category_id].contract.nb_days_to_complete : 0;
        let days_to_ship = quotation_item_categories[elm.item_category_id].contract.days_to_ship ? quotation_item_categories[elm.item_category_id].contract.days_to_ship : 0;
        let days_to_complete = nb_days_to_complete + days_to_ship;
        let date_h = {
          target: {
            name: "installation_date",
            value: type === "cancel" ? null : formatApiDate(inst_date.setDate(inst_date.getDate() + days_to_complete)),
          },
        };
        this.onChangeItemManufactureDetails(date_h, elm.id);

        let num_days = {
          target: {
            name: "days_to_installation",
            value: type === "cancel" ? null : days_to_complete,
          },
        };
        this.onChangeItemManufactureDetails(num_days, elm.id);
      }
    });
  }
  // ===End===

  // Phase 3: automatic calculate Installation Date
  // ===Start===
  updateInstallationDateAuto() {
    let { quotation } = this.state;
    let { quotation_items, quotation_item_categories } = quotation;
    quotation_items.forEach((elm) => {
      let inst_date = new Date();
      let nb_days_to_complete = quotation_item_categories[elm.item_category_id].contract.nb_days_to_complete ? quotation_item_categories[elm.item_category_id].contract.nb_days_to_complete : 0;
      let days_to_ship = quotation_item_categories[elm.item_category_id].contract.days_to_ship ? quotation_item_categories[elm.item_category_id].contract.days_to_ship : 0;
      let days_to_complete = nb_days_to_complete + days_to_ship;
      let date_h = {
        target: {
          name: "installation_date",
          value: formatApiDate(inst_date.setDate(inst_date.getDate() + days_to_complete)),
        },
      };
      this.onChangeItemManufactureDetails(date_h, elm.id);

      let num_days = {
        target: {
          name: "days_to_installation",
          value: days_to_complete,
        },
      };
      this.onChangeItemManufactureDetails(num_days, elm.id);
    });
  }
  // ===End===

  checkInstallationChanged(quotation, current_installation_date) {
    let { quotation_items } = quotation;
    let changed_installation_date = {};
    quotation_items.forEach((item) => {
      if (current_installation_date[item.id] && current_installation_date[item.id] !== item.installation_date) {
        changed_installation_date[item.id] = current_installation_date[item.id];
      }
    });
    return changed_installation_date;
  }

  async updateInformedDate(update_type = null, category_key = null, type = null) {
    let { quotation, current_installation_date } = this.state;
    let changed_installation_date = this.checkInstallationChanged(quotation, current_installation_date);

    this.setState({ load_cate: 0, cate_length: 0 });

    let params = {
      quo_code: quotation.code,
      quotation_item_categories: quotation.quotation_item_categories,
      quotation_items: quotation.quotation_items,
      user_id: this.props.current_user.id,
      owner: quotation.owner,
      changed_installation_date: changed_installation_date,
    };

    if (update_type === "inform_date") {
      await this.updateNewInformedDate(category_key, type);
      await this.updateInstallationDate(category_key, type);
      params.quotation_item_categories = quotation.quotation_item_categories;
      params.quotation_items = quotation.quotation_items;
      delete params.changed_installation_date;
    }

    axios
      .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}/update_informed_dates`, params)
      .then((response) => {
        let current_installation_date = {};
        quotation.quotation_items.forEach((quo_item) => {
          current_installation_date[quo_item.id] = quo_item.installation_date;
        });

        alert("Inform date updated!");

        this.setState({
          is_loading: false,
          current_installation_date: current_installation_date,
          load_cate: 0,
        });
        this.checkItemContract(quotation, "update", true);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false });
      });

    this.setState({ is_loading: true });
  }

  onChangeFormTab(tab) {
    this.setState({ current_tab: tab });
  }

  onChangeCustomerStation(e, field) {
    let { quotation } = this.state;
    quotation[field] = {
      ...quotation[field],
      [e.target.name]: e.target.value,
    };

    this.setState({
      quotation: quotation,
      is_updated: true,
      is_updated_soldto_client: e.target.name == "sold_to_code" ? true : this.state.is_updated_soldto_client,
    });
  }

  onChangeCustomerStationAddress(value, name, field) {
    let { quotation } = this.state;
    quotation[field].address = {
      ...quotation[field].address,
      [name]: value,
    };

    if (name === "district_id") {
      quotation[field].address.sub_district_id = "";
    }

    this.setState({ quotation: quotation, is_updated: true });
  }

  onChangeCustomerProvince(value, e) {
    let { quotation, selected_station } = this.state;
    if (selected_station === "") {
      let reset_cates = Object.keys(quotation.quotation_item_categories);
      quotation.client_station.address.province_id = value;
      quotation.client_station.address.district_id = "";
      quotation.client_station.address.sub_district_id = "";
      this.setState({
        quotation: quotation,
        is_updated: true,
        is_reset_contract_cate: reset_cates,
      });
      // Find new distance from a distance matrix
      this.getDistanceFromMatrix(value);
    }
  }

  onChangeCustomerDistrict(value, e) {
    this.onChangeCustomerStationAddress(value, "district_id", FIELD_CLIENT_STATION);
  }

  onChangeCustomerSubDistrict(value, e) {
    this.onChangeCustomerStationAddress(value, "sub_district_id", FIELD_CLIENT_STATION);
  }

  onChangeBuyerProvince(value, e) {
    let { quotation, selected_buyer } = this.state;
    if (stringIsEmpty(selected_buyer)) {
      quotation.buyer.address.province_id = value;
      quotation.buyer.address.district_id = "";
      quotation.buyer.address.sub_district_id = "";
      this.setState({ quotation: quotation, is_updated: true });
      // Find new distance from a distance matrix
    }
  }

  onChangeBuyerDistrict(value, e) {
    this.onChangeCustomerStationAddress(value, "district_id", FIELD_BUYER);
  }

  onChangeBuyerSubDistrict(value, e) {
    this.onChangeCustomerStationAddress(value, "sub_district_id", FIELD_BUYER);
  }

  onClickSelectStation(value, e) {
    let { quotation, selected_station, is_updated } = this.state;
    let display_station = false;
    let changing_value = value !== selected_station;
    let reset_cates = Object.keys(quotation.quotation_item_categories);

    if (value !== null) {
      quotation.client_station = e;
      selected_station = e.value;
      display_station = true;
    } else {
      quotation.client_station = cloneDeep(NEW_CUSTOMER);
      selected_station = "";
    }

    let province_id = e.address.province_id;
    let zone_type = {};

    this.getSelectedItemOptions(quotation, true);

    if (this.state.zones_data.length > 0) {
      this.state.zones_data.forEach((zone) => {
        if (zone.name !== "ทั่วประเทศ") {
          const filter = zone.province_ids.find((_id) => _id === province_id);
          if (filter) {
            zone_type = zone;
          }
        }
      });
    }

    this.setState({ zone_type: zone_type });

    // Recalculate expenses
    for (var key in quotation.quotation_item_categories) {
      this.reCalExpense(quotation.quotation_items, quotation.quotation_item_categories[key], quotation.client_station.distance);
    }

    if (quotation.quotation_items?.length > 0) this.onAddItems(quotation.quotation_items, true)

    this.setState({
      quotation: quotation,
      selected_station: selected_station,
      display_station: display_station,
      is_updated: changing_value || is_updated,
      is_reset_contract_cate: reset_cates,
    });
  }

  onClickSelectBuyer(value, e) {
    let { quotation, selected_buyer, is_updated } = this.state;
    let display_buyer = false;
    let changing_value = value !== selected_buyer;
    if (value !== null) {
      quotation.buyer = e;
      selected_buyer = e.value;
      display_buyer = true;
    } else {
      quotation.buyer = cloneDeep(NEW_CUSTOMER);
      selected_buyer = "";
    }

    this.setState({
      quotation: quotation,
      selected_buyer: selected_buyer,
      display_buyer: display_buyer,
      is_updated: changing_value || is_updated,
    });
  }

  async onAddItems(items, is_update_item = false) {
    const { quotation } = this.state;
    let item_cate_ids = [];

    for (let item of items) {
      if (item.is_parent && item?.parent_item_ids?.length > 0 && item?.parent_item_ids[0] !== null) {
        if (!item.from_parent || is_update_item) {
          item_cate_ids.push(item.item_category_id);
          await this.onAddItem(item, item.qty || 1, "", false, items, is_update_item);
        }
      } else {
        if (!item.from_parent && !item.is_parent) {
          const is_duplicate = quotation.quotation_items.find((q_item) => q_item.item_id === item.id);
          if (!is_duplicate || is_update_item) {
            item_cate_ids.push(item.item_category_id);
            await this.onAddItem(item, item.qty || 1, "", false, items, is_update_item);
          }
        }
      }
    }

    this.setState({
      is_reset_contract_cate: toUniqueArr(item_cate_ids),
    });
  }

  async onAddItem(item, quantity, comment, is_mini_select = false, multipleAddItem = false, is_update_item = false) {
    this.setState({ is_loading: true });

    const station_id = this.state.quotation.client_station.id
    let params = {
      filter: { station_id: station_id },
    };

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/items/${parseInt(is_update_item ? item.item_id : item.id)}`, { params: params });
      let witholding_tax = 0
      let selected_vendor_item = this.state.selected_vendor_item;
      let selected_dc_item = this.state.selected_dc_item;
      let { quotation, selected_item_options, item_options, select_sub_item_list } = this.state;
      let quo_cates = quotation.quotation_item_categories;
      let is_reset_contract_cate = false;
      let selected_item = response.data;
      let new_item = Object.assign({}, selected_item);
      let find_duplicate_sub = null;
      selected_item_options.push(selected_item);
      new_item.qty = quantity ? quantity : 1;
      new_item.item_id = is_update_item ? item.item_id : item.id;
      new_item.id = generateID();
      new_item.note = comment;
      new_item.issued = false;
      new_item.is_new = true;

      if (new_item.from_parent) {
        new_item["parent_item_id"] = new_item.from_parent_item_id;
      }

      // Add subitem to item list with make as parent
      if (new_item?.is_parent && new_item?.parent_items?.length > 0) {
        new_item.qty = 1;

        const new_sub_item_list = [
          ...select_sub_item_list,
          ...new_item.parent_items.map((item) => {
            return {
              sub_item_id: item.item_id,
              parent_item_id: new_item.item_id,
            };
          }),
        ];

        if (is_mini_select) this.onMatchSubItemList(new_sub_item_list);

        await new_item.parent_items.forEach(async (p_item) => {
          const find_select_sub_item_list = select_sub_item_list.find((s_item) => s_item.sub_item_id === p_item.item_id && s_item.parent_item_id === new_item.item_id);

          if (!find_duplicate_sub) find_duplicate_sub = quotation.quotation_items.find((q_item) => q_item.item_id === p_item.item_id);

          if (!find_select_sub_item_list && !is_mini_select) return;

          await axios
            .get(`${process.env.REACT_APP_API_URL}/items/${parseInt(p_item.item_id)}`, { params: params })
            .then((response) => {
              let sub_item = { ...response.data };
              let new_sub_item = Object.assign({}, sub_item);
              let find_qty = item?.sub_items?.find(({ item_id }) => item_id === sub_item.id)?.qty || null;
              let quo_sub_item = quotation.quotation_items.findIndex((item) => item.item_id === p_item.item_id && item.parent_item_id === new_item.item_id);

              new_sub_item.from_parent = true;
              new_sub_item["from_parent_item_id"] = new_item.item_id;
              new_sub_item.id = generateID();
              new_sub_item.parent_item_id = new_item.item_id;
              new_sub_item.qty = find_qty ? find_qty : 1;
              new_sub_item.item_id = sub_item.id;
              new_item.price = new_item.price + new_sub_item.price * (find_qty ? find_qty : 1);
              new_item["parent_price"] = new_item.price;

              //
              const find_item_index = quotation.quotation_items.findIndex((n_item) => n_item.item_id === new_item.item_id);

              if (find_item_index !== -1) {
                quotation.quotation_items[find_item_index].price = new_item.price;
                quotation.quotation_items[find_item_index].parent_price = new_item.parent_price;
              }

              if (is_update_item) {
                quotation.quotation_items[quo_sub_item] = new_sub_item
              }
              else {
                if (quo_sub_item < 0) quotation.quotation_items.push(new_sub_item);
              }
              if (new_sub_item.is_duplicate) this.setState({ check_duplicate: true })

              this.reCalExpense(quotation.quotation_items, quo_cates[new_item.item_category_id]);
              witholding_tax = quotation.quotation_items.reduce((prev, curr) => prev + curr.wht, witholding_tax)
              this.setState({ quotation: quotation });

            })
            .catch((err) => {
              console.log("err", err);
            });
        });
      }

      const quo_items = quotation.quotation_items.findIndex((item) => item.item_id === new_item.item_id);


      // Add a new item category to quotation
      if (quo_cates[new_item.item_category_id] === undefined) {
        is_reset_contract_cate = [new_item.item_category_id];
        quo_cates[new_item.item_category_id] = this.genQuotationItemCategory(new_item.item_category_id);
      } else {
        if (quo_cates[new_item.item_category_id].contract_id) {
          // Set contract price for the new added item
          this.setItemContractPriceByContractId(quo_cates[new_item.item_category_id].contract_id, new_item.item_category_id, item.id, new_item.id);
        }
      }
      new_item = initWarrantyItem(new_item, quo_cates);



      if (is_update_item) {
        quotation.quotation_items[quo_items] = new_item
      }
      else {
        if (quo_items < 0) quotation.quotation_items.push(new_item);
      }
      // Recalculate fees


      // Add item fee and discount
      if (!find_duplicate_sub) quo_cates[new_item.item_category_id] = await genExpenseOnItemAdded(quo_cates[new_item.item_category_id], selected_item, new_item.qty);

      // set unit if any
      if (new_item.unit === null) {
        new_item.unit = quo_cates[new_item.item_category_id].unit;
      }

      if (!find_duplicate_sub) {
        if (item.item_category.dc_id === 0) {
          selected_vendor_item = selected_vendor_item + 1;
        } else {
          selected_dc_item = selected_dc_item + 1;
        }
      }

      if (item.is_parent) {
        const find_select_subs = this.state.select_sub_item_list.filter((sub_item) => sub_item.parent_item_id === item.id);
        if (find_select_subs.length === item.parent_item_ids.length) item_options = this.setSelectedParentFlagOnItemOption(item.id, true);
      } else {
        item_options = this.setSelectedFlagOnItemOption(item.id, true);
      }


      if (multipleAddItem && multipleAddItem?.length === quotation.quotation_items.length) {
        this.setState({ check_duplicate: true })
      } else {
        if (!multipleAddItem)
          this.setState({ check_duplicate: true })
      }



      this.reCalExpense(quotation.quotation_items, quo_cates[new_item.item_category_id]);

      witholding_tax = quotation.quotation_items.reduce((prev, curr) => prev + curr.wht, witholding_tax)

      this.setState({
        quotation: quotation,
        witholding_tax: witholding_tax,
        selected_item_options: selected_item_options,
        item_options: item_options,
        is_reset_contract_cate: is_reset_contract_cate,
        selected_dc_item: selected_dc_item,
        selected_vendor_item: selected_vendor_item,
      });

      setTimeout(() => {
        this.onReCalContractDate();
        this.setState({
          is_loading: false,
          is_updated: true,
        });
      }, 1000);
    } catch (error) {
      this.setState({
        is_loading: false,
      });
    }
  }

  onDeleteItem(item) {
    let { quotation, item_options, selected_dc_item, selected_vendor_item, select_sub_item_list } = this.state;
    let item_category_id = item.item_category_id;
    let new_select_sub_item_list = [...select_sub_item_list];

    let selectDCItems = selected_dc_item;
    let SelectVendorItems = selected_vendor_item;

    let temp_quotation_item_categories = quotation.quotation_item_categories[item_category_id];

    if (quotation.quotation_item_categories[item_category_id] !== undefined && !item.from_parent) {
      if (quotation.quotation_item_categories[item_category_id].dc_id === 1) selectDCItems = selectDCItems - 1;
      else SelectVendorItems = SelectVendorItems - 1;
    }

    // delete item from quotation
    quotation = deleteItem(quotation, item.id);
    if (quotation.quotation_item_categories[item_category_id] !== undefined) {
      this.reCalExpense(quotation.quotation_items, quotation.quotation_item_categories[item_category_id], quotation.client_station.distance);
    }

    if (item.from_parent) {
      const parent_item_id = item.parent_item_id;
      const find_select_item_index = new_select_sub_item_list.findIndex((sub) => sub.sub_item_id === item.item_id);

      if (find_select_item_index > -1) new_select_sub_item_list.splice(find_select_item_index, 1);

      let find_parent_items = quotation.quotation_items.filter((item) => item.parent_item_id === parent_item_id);
      let find_main_parent_index = quotation.quotation_items.findIndex((item) => item.item_id === parent_item_id);

      if (find_main_parent_index > -1) {
        quotation.quotation_items[find_main_parent_index].price = quotation.quotation_items[find_main_parent_index].price - item.price;
        quotation.quotation_items[find_main_parent_index].parent_price = quotation.quotation_items[find_main_parent_index].parent_price - item.price;
      }

      if (find_parent_items.length === 0) {
        item_options = this.setSelectedFlagOnItemOption(parent_item_id, false);
        // const parent_index = quotation.quotation_items.findIndex(({ item_id }) => item_id === parent_item_id)
        if (temp_quotation_item_categories !== undefined) {
          if (temp_quotation_item_categories.dc_id === 1) selectDCItems = selectDCItems - 1;
          else SelectVendorItems = SelectVendorItems - 1;
        }
      }
    }

    const witholding_tax = quotation.quotation_items.reduce((prev, curr) => prev + curr.wht, 0)

    this.onMatchSubItemList(new_select_sub_item_list);

    // update selected flag on item options
    item_options = this.setSelectedFlagOnItemOption(item.item_id, false);

    this.setState({
      witholding_tax: witholding_tax,
      select_sub_item_list: new_select_sub_item_list,
      selected_dc_item: selectDCItems,
      selected_vendor_item: SelectVendorItems,
      quotation: quotation,
      item_options: item_options,
      is_updated: true,
    });
  }

  onChangeQuotationDetails(e) {
    let quotation = this.state.quotation;
    quotation = {
      ...quotation,
      [e.target.name]: e.target.value,
    };
    this.setState({ quotation: quotation, is_updated: true });
  }

  onChangeQuoHasBuyer(has_buyer) {
    let quotation = this.state.quotation;
    quotation.has_buyer = has_buyer;
    this.setState({ quotation: quotation, is_updated: true });
  }

  onChangeQuoDate(date) {
    let quo = this.state.quotation;
    quo.quotation_date = new Date(date);
    this.setState({ quotation: quo, is_updated: true });
  }

  onChangeOwner(e) {
    let quo = this.state.quotation;
    quo.owner = e.target.value;
    this.setState({ quotation: quo, is_updated: true });
  }

  onChangeQuoContractDate(date) {
    let quo = this.state.quotation;
    quo.approved_date = date;

    this.setState({ quotation: quo, is_updated: true });
  }

  onReCalContractDate() {
    let quo = this.state.quotation;
    let reset_cates = Object.keys(quo.quotation_item_categories);
    this.setState({
      is_updated: true,
      is_reset_contract_cate: reset_cates,
      reset_pp_key: uniqueKey(),
    });
  }

  onChangeItemDetails(e, id) {
    let { quotation } = this.state;
    let { quotation_items, quotation_item_categories } = quotation;
    let de_item_index = quotation_items.findIndex((x) => x.id === id);

    quotation_items[de_item_index][e.target.name] = e.target.value;

    if (quotation_items[de_item_index]?.from_parent) {
      const find_parent_item_index = quotation_items.findIndex(({ item_id }) => item_id === quotation_items[de_item_index].parent_item_id);
      const find_sub_items = quotation_items.filter(({ parent_item_id, from_parent }) => parent_item_id === quotation_items[de_item_index].parent_item_id && from_parent);

      if (find_parent_item_index > -1) {
        quotation_items[find_parent_item_index].price = 0;
        quotation_items[find_parent_item_index].parent_price = 0;

        find_sub_items.forEach(({ price, qty }) => {
          quotation_items[find_parent_item_index].price = quotation_items[find_parent_item_index].price + price;
          quotation_items[find_parent_item_index].parent_price = quotation_items[find_parent_item_index].parent_price + price * qty;
        });
      }
    }

    let witholding_tax = 0
    quotation_items.forEach((item) => {
      if (item.wht) witholding_tax = witholding_tax + item.wht
    })

    quotation.witholding_tax = witholding_tax


    quotation_items[de_item_index] = {
      ...quotation_items[de_item_index],
      [e.target.name]: e.target.value,
    };

    // Recalculate expenses
    this.reCalExpense(quotation_items, quotation_item_categories[quotation_items[de_item_index].item_category_id]);
    this.setState({ quotation: quotation, is_updated: true, witholding_tax: witholding_tax });
  }

  onChangeCategoryName(e, cate_id) {
    let { quotation } = this.state;
    quotation.quotation_item_categories[cate_id].sys_name = e.target.value;
    this.setState({ quotation: quotation });
  }

  onChangeRemarkDetail(e, id, item_category_id) {
    let { quotation } = this.state;

    quotation.quotation_item_categories[item_category_id].remark = e.target.value;

    this.setState({ quotation: quotation, is_updated: true });
  }

  onChangeShippingDetails(e, id, item_category_id) {
    let { quotation } = this.state;
    let category_expenses = quotation.quotation_item_categories[item_category_id].item_category_expenses;
    let de_item_index = category_expenses.findIndex((x) => x.id === id);
    category_expenses[de_item_index] = {
      ...category_expenses[de_item_index],
      [e.target.name]: e.target.value,
    };

    // update flag
    if (e.target.name === "qty") {
      category_expenses[de_item_index].is_custom_qty = true;
    }
    // Recalculate expenses
    this.reCalExpense(quotation.quotation_items, quotation.quotation_item_categories[item_category_id]);
    this.setState({ quotation: quotation, is_updated: true });
  }

  onAddShippingCost(id, index, item_category_id) {
    let { quotation } = this.state;
    let category_expenses = quotation.quotation_item_categories[item_category_id].item_category_expenses;
    let edited_index = category_expenses.findIndex((x) => x.id === id);
    let clone_cost = cloneDeep(category_expenses[edited_index]);

    clone_cost.id = generateID();
    clone_cost.position = index;
    clone_cost.is_new = false;
    category_expenses.push(clone_cost);
    // Reset
    category_expenses[edited_index] = cloneDeep(new_expense);
    category_expenses[edited_index].item_category_id = item_category_id;

    this.setState({ quotation: quotation, is_updated: true });
  }

  onDeleteShippingCost(elm) {
    let { quotation } = this.state;
    let { quotation_item_categories } = quotation;
    quotation_item_categories[elm.item_category_id].item_category_expenses = quotation_item_categories[elm.item_category_id].item_category_expenses.filter((temp) => (isDiscount(elm) ? temp.name !== elm.name : temp.id !== elm.id));

    this.reCalExpense(quotation.quotation_items, quotation_item_categories[elm.item_category_id], quotation.client_station.distance);
    this.setState({ quotation: quotation, is_updated: true });
  }

  onChangeContract(value, e, item_category_id) {
    let { quotation } = this.state;
    let item_ids = quotation.quotation_items.reduce((results, elm) => {
      if (elm.item_category_id == item_category_id) results.push(elm.item_id);
      return results;
    }, []);

    let errors = this.setContractByItemCategory(
      {
        is_active: true,
        id: value,
        item_category_id: item_category_id,
        item_ids: item_ids,
      },
      "update",
      false,
      true
    );

    this.setState({ quotation: quotation, errors_pprices: errors });
  }

  onResetContractPrice(item_category_id_arr) {
    this.setState({ is_reset_contract_cate: item_category_id_arr });
  }

  setSelectedFlagOnItemOption(item_id, flag) {
    let { item_options } = this.state;
    let item_index = item_options.findIndex((elm) => elm.id === item_id);
    if (item_index !== -1) {
      item_options[item_index].is_selected = flag;
    }

    return item_options;
  }

  setSelectedParentFlagOnItemOption(item_id, flag) {
    let { item_options } = this.state;
    let item_index = item_options.findIndex((elm) => elm.id === item_id);
    if (item_index !== -1) {
      item_options[item_index].is_sub_selected = flag;
      item_options[item_index].is_selected = flag;
    }

    return item_options;
  }

  handleDisplayStation() {
    this.setState({ display_station: true });
  }

  handleHideStation() {
    let { quotation } = this.state;
    quotation.client_station = cloneDeep(NEW_CUSTOMER);
    this.setState({
      display_station: false,
      quotation: quotation,
      selected_station: "",
      is_updated: true,
      is_updated_soldto_client: false,
      reFilteringClientStation: uniqueKey(),
    });
  }

  handleDisplayBuyer() {
    this.setState({ display_buyer: true });
  }

  handleHideBuyer() {
    let { quotation } = this.state;
    quotation.buyer = cloneDeep(NEW_CUSTOMER);
    quotation.buyer.buyer_tel = "";
    this.setState({
      display_buyer: false,
      quotation: quotation,
      selected_buyer: "",
      is_updated: true,
      is_updated_soldto_buyer: false,
      reFilteringBuyer: uniqueKey(),
    });
  }

  onPreviewSubmit(e, newState) {
    let { quotation, } = this.state
    let is_duplicate = false
    let new_duplicate_list = []

    quotation.quotation_items.forEach((item) => {
      if (item.is_duplicate) {
        new_duplicate_list.push(item)
        is_duplicate = true
      }
    })

    if (!is_duplicate) this.onHandleSubmit(e, newState)
    else this.setState({ temp_stage_save: { e: e, newState: newState } })

    this.setState({ show_save_duplicate: is_duplicate, duplicate_list: new_duplicate_list })

  }

  onnCancelPreviewSaveState() {
    this.setState({ temp_stage_save: {}, show_save_duplicate: false, duplicate_list: [] })
  }

  onPreviewSubmitApproved() {
    let { quotation, } = this.state
    let is_duplicate = false
    let new_duplicate_list = []

    quotation.quotation_items.forEach((item) => {
      if (item.is_duplicate) {
        new_duplicate_list.push(item)
        is_duplicate = true
      }
    })

    if (!is_duplicate) this.onHandleSubmitApprove()

    this.setState({ show_save_duplicate: is_duplicate, duplicate_list: new_duplicate_list })

  }

  onHandleSubmit(e, newState) {
    let { quotation } = this.state;
    let errors = validateQuotation(quotation, newState);
    this.setState({ load_cate: 0, cate_length: 0 });

    if (objectIsEmpty(errors)) {
      if (quotation.id !== null) {
        this.putQuotation(newState);
      } else {
        this.postNewQuotation(newState);
      }
    } else {
      if (errors["suggest_fee"]) {
        alert(errors["suggest_fee"]);
      } else {
        alert("กรุณากรอกข้อมูลให้ครบถ้วน");
        this.setState({ errors: errors });
      }
    }
  }

  /* ========================================================= */
  /********* Manufacture (Start) *********/
  onChangeCategoryInformedDate(date, cate_id) {
    let { quotation } = this.state;

    if (date instanceof Date) {
      quotation.quotation_item_categories[cate_id].informed_date = formatApiDate(date);
    }

    this.setState({ quotation: quotation });
  }

  onGetWarrantyCodes(item_id) {
    let { quotation } = this.state;
    let index = objectIndexOf(quotation.quotation_items, "id", item_id);
    let quotation_item_cate = quotation.quotation_item_categories[quotation.quotation_items[index].item_category_id];

    if (quotation_item_cate.contract_id && quotation.id && !quotation.quotation_items[index].is_new) {
      let asset_code = quotation.quotation_items[index].item.asset_code ? quotation.quotation_items[index].item.asset_code : quotation_item_cate.item_category.code;
      let body = {
        is_new: quotation.quotation_items[index].is_new,
        for_quotation: true,
        quotation_item_id: item_id,
        item_category_id: quotation.quotation_items[index].item_category_id,
        asset_code: asset_code,
        contract_id: quotation_item_cate.contract_id,
        qty: quotation.quotation_items[index].qty,
      };

      if (quotation.quotation_items[index].item.asset_code) {
        body.item_id = quotation.quotation_items[index].item_id;
      }

      this.setState({
        is_loading: true,
      });
      axios
        .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}/get_warranty_codes`, body)
        .then((response) => {
          quotation.quotation_items[index].warranty_codes = response.data;

          this.setState({
            quotation: quotation,
            is_loading: false,
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data) {
            alert(error.response.data.Message);
          }
          this.setState({
            is_loading: false,
          });
        })
        .then(function () {
          // always executed
        });
    } else {
      if (!quotation.id || quotation.quotation_items[index].is_new) {
        alert("กรุณา save ก่อนออกรหัสทรัพย์สิน");
      } else {
        alert(`ไม่สามารถขอรหัสทรัพย์สินได้ เนื่องจากยังไม่ได้ระบุสัญญาใน ${quotation_item_cate.sys_name}`);
      }
    }
  }

  onDeleteWarrantyCodes(item_id) {
    let { quotation } = this.state;
    let index = objectIndexOf(quotation.quotation_items, "id", item_id);

    let body = {
      for_quotation: true,
      quotation_item_id: item_id,
    };

    this.setState({
      is_loading: true,
    });
    axios
      .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.po}/delete_warranty_codes`, body)
      .then((response) => {
        quotation.quotation_items[index].warranty_codes = [];

        this.setState({
          quotation: quotation,
          is_loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data) {
          alert(error.response.data.Message);
        }
        this.setState({
          is_loading: false,
        });
      })
      .then(function () {
        // always executed
      });
  }

  onChangeItemManufactureDetails = (e, id) => {
    let { quotation } = this.state;
    let { quotation_items } = quotation;
    let de_item_index = quotation_items.findIndex((x) => x.id === id);

    quotation_items[de_item_index] = {
      ...quotation_items[de_item_index],
      [e.target.name]: e.target.value,
    };
    quotation_items = initDaystoInstallation(quotation);
    quotation_items[de_item_index].expiration_date = formatApiDate(getWarrantyExpirationDate(quotation_items[de_item_index]));
    this.setState({ quotation: quotation, is_updated: true });
  };
  /********* Manufacture (END) *********/

  /* Issue PO (start) */
  onHandleIssuePoModal(value) {
    this.setState({ isIssuingPO: value });
  }
  onSubmitIssuePo(items) {
    let { quotation } = this.state;

    axios
      .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}`, {
        quotation: this.state.quotation,
      })
      .then((res) => {
        let po = {};
        let po_items = [];
        let item_ids = [];
        // init po
        po.state = ORDER_STATES.in_progress.name;
        po.quotation_id = quotation.id;
        po.user_id = this.props.current_user.id;
        po.order_item_category_ids = [];
        // add po items
        items.forEach((item) => {
          let quo_item = quotation.quotation_items.filter((elm) => elm.id === item.id)[0];
          quo_item.issued = true;

          po_items.push({
            price: item.price + item.expense_ratio,
            quotation_item_id: item.id,
            item_id: item.item_id,
            is_mbom: item.is_mbom,
            is_parent: item.is_parent,
          });
          po.order_item_category_ids.push(item.item_category_id);
          item_ids.push(item.item_id);
        });

        po.order_item_expenses = {};
        for (let quo_item_cate_id in quotation.quotation_item_categories) {
          if (po.order_item_category_ids.includes(parseInt(quo_item_cate_id))) {
            let quo_item_cate = quotation.quotation_item_categories[quo_item_cate_id];
            po.order_item_expenses[quo_item_cate_id] = [];

            for (let elm of quo_item_cate.item_category_expenses) {
              if (isItemExpense(elm.resource_type)) {
                if (item_ids.includes(elm.resource_id)) {
                  po.order_item_expenses[quo_item_cate_id].push(elm);
                }
              } else {
                po.order_item_expenses[quo_item_cate_id].push(elm);
              }
            }
          }
        }

        po.order_item_category_ids = toUniqueArr(po.order_item_category_ids);
        po.purchasing_order_items = po_items;
        po.item_ids = item_ids;

        // check if there is an un-selected item
        let unissued_items = quotation.quotation_items.filter((elm) => elm.issued === false);
        if (unissued_items.length === 0) {
          quotation.state = QUO_STATES.order_issued_completed.name;
        } else {
          quotation.state = QUO_STATES.order_issued_partially.name;
        }

        console.log('po', po)
        console.log('quotation', quotation)



        axios
          .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}/issue_po`, {
            current_installation_date: this.state.current_installation_date,
            quotation: quotation,
            po: po,
          })
          .then((response) => {
            window.location.href = `/${API_RESOURCES.order}/${response.data.id}`;
          })
          .catch((error) => {
            console.log(error);
            alert("ERROR: เกิดข้อผิดพลาด" + error);
            setTimeout(() => {
              window.location.reload();
            }, 800);
            this.setState({ is_loading: false });
          });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false });
      });

    this.setState({ is_loading: true });
    this.onHandleIssuePoModal(false);
  }

  issuePo() {
    if (this.state.isIssuingPO) {
      const { quotation } = this.state;
      let { quotation_item_categories } = this.state.quotation;
      let inform_manu_completed = true;
      let inform_dc_manu_completed = true;
      let all_dc_issued = false;
      let all_vendor_issued = false;

      let have_dc_product = false;
      let have_vendor_product = false;

      let select_any_vendor = null;
      let select_any_dc = null;

      Object.keys(quotation_item_categories).forEach((key) => {
        if (quotation_item_categories[key].item_category.dc_id === 0) {
          have_vendor_product = true;

          if (quotation_item_categories[key].informed_date === null) {
            inform_manu_completed = false;
          }

          // quotation.quotation_items.forEach((item) => {
          //   if (parseInt(item.item_category_id) === parseInt(key)) {
          //     if (item.issued) {
          //       inform_manu_completed = false
          //     }
          //   }
          // })
          if (select_any_vendor === null || select_any_vendor) {
            select_any_vendor = quotation.quotation_items.find((item) => parseInt(item.item_category_id) === parseInt(key) && !item.issued) ? false : true;
          }
        } else if (quotation_item_categories[key].informed_date === null && quotation_item_categories[key].item_category.dc_id === 1) {
          have_dc_product = true;

          quotation.quotation_items.forEach((item) => {
            if (parseInt(item.item_category_id) === parseInt(key)) {
              if (item.qty > item.active) {
                inform_dc_manu_completed = false;
              }
            }
          });

          if (select_any_dc === null || select_any_dc) {
            select_any_dc = quotation.quotation_items.find((item) => parseInt(item.item_category_id) === parseInt(key) && !item.issued) ? false : true;
          }
        }
      });

      const findDcItems = quotation.quotation_items.filter((quo) => quo.item_category.dc_id === 1);
      const findAllDcIssued = quotation.quotation_items.filter((quo) => quo.issued && quo.item_category.dc_id === 1);

      const findVendorItems = quotation.quotation_items.filter((quo) => quo.item_category.dc_id === 0);
      const findAllVendorIssued = quotation.quotation_items.filter((quo) => quo.issued && quo.item_category.dc_id === 0);

      if (findAllDcIssued.length === findDcItems.length) {
        all_dc_issued = true;
      }

      if (findVendorItems.length === findAllVendorIssued.length) {
        all_vendor_issued = true;
      }

      return (
        <QuotationFormIssuePo
          items={this.state.quotation.quotation_items}
          item_categories={this.state.quotation.quotation_item_categories}
          onHandleIssuePoModal={this.onHandleIssuePoModal}
          onSubmitIssuePo={this.onSubmitIssuePo}
          inform_manu_completed={inform_manu_completed}
          inform_dc_manu_completed={inform_dc_manu_completed}
          all_dc_issued={all_dc_issued}
          all_vendor_issued={all_vendor_issued}
          select_any_vendor={select_any_vendor}
          select_any_dc={select_any_dc}
          have_dc_product={have_dc_product}
          have_vendor_product={have_vendor_product}
        />
      );
    }
  }
  /* Issue PO (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Cancel (start) */
  onHandleCancelModal(value) {
    this.setState({ is_canceling: value });
  }
  onCancelItemsModal() {
    this.updateQuotationState(QUO_STATES.order_issued_completed.name);
  }
  onCancel() {
    if (this.state.quotation.cancel_note) {
      this.updateQuotationState(QUO_STATES.cancelled.name);
    } else {
      alert("โปรดระบุเหตุผล");
    }
  }
  onCancelCloneEdit() {
    let { quotation, clone_from_quo } = this.state;

    let { prev_state } = this.checkPrevClonedState(clone_from_quo.quotation_items);

    axios
      .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${clone_from_quo.id}/update_state`, {
        state: prev_state,
      })
      .then(() => {
        this.onDeleteQuotation(quotation.id);
        window.location.href = `/${API_RESOURCES.quotation}`;
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({ is_loading: true });
  }

  onHandleRejectModal(value) {
    this.setState({ is_rejecting: value });
  }
  onReject() {
    if (this.state.quotation.cancel_note) {
      this.updateQuotationState(QUO_STATES.rejected.name);
    } else {
      alert("โปรดระบุเหตุผล");
    }
  }
  /* Cancel (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Delete (start) */
  onHandleModalDelete(value) {
    this.setState({ is_deleting: value });
  }

  onDeleteQuotation(id) {
    // add dummy data and header to fix 415 Unsupported Media Type
    axios
      .delete(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${id}`, { data: {}, headers: { "Content-Type": "text/plain" } })
      .then(() => {
        window.location.href = `/${API_RESOURCES.quotation}`;
      })
      .catch((error) => {
        console.log(error);
        this.setState({ is_loading: false });
      });
  }

  displayDeleteConfirmation() {
    if (this.state.is_deleting) {
      return <ModalConfirmation isOpen={true} title="ลบใบเสนอราคา" content="คุณแน่ใจที่จะลบใบเสนอราคาอันนี้ ?" onConfirm={() => this.onDeleteQuotation(this.state.quotation.id)} onCancel={() => this.onHandleModalDelete(false)} />;
    }
  }
  /* Delete (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Approve (start) */
  onHandleModalApprove(value) {
    this.setState({ is_approving: value });
  }

  displayApproveConfirmation() {
    if (this.state.is_approving) {
      return (
        <ModalConfirmation
          isOpen={true}
          title="อนุมัติใบเสนอราคา"
          content={
            <React.Fragment>
              <p>
                อนุมัติใบเสนอราคา โดยใช้วันที่อนุมัติ <b>{formatDate(this.state.quotation.approved_date)}</b>
              </p>
              <div className="color-red mt-3">* กรณีอนุมัติแล้วจะไม่สามารถทำการแก้ไขวันที่อนุมัติได้อีก</div>
            </React.Fragment>
          }
          btnOK="อนุมัติ"
          onConfirm={() => this.updateQuotationState(QUO_STATES.approved.name)}
          onCancel={() => this.onHandleModalApprove(false)}
        />
      );
    }
  }
  /* Approve (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Back (start) */
  backToQuotationIndex() {
    this.props.history.push({
      pathname: `/${API_RESOURCES.quotation}`,
      search: this.props.location.search,
    });
  }
  onHandleModalBack(value) {
    this.setState({ is_leaving: value });
  }
  onClickBack() {
    if (this.state.is_updated) {
      this.onHandleModalBack(true);
    } else {
      this.backToQuotationIndex();
    }
  }
  displayBackConfirmation() {
    if (this.state.is_leaving) {
      return <ModalConfirmation isOpen={true} title="บันทึกใบเสนอราคา ?" content="มีการแก้ไขที่ยังไม่ได้ถูกบันทึก ต้องการบันทึก?" btnDanger="ไม่บันทึก" onConfirm={this.onPreviewSubmit} onCustomAction={this.backToQuotationIndex} onCancel={() => this.onHandleModalBack(false)} />;
    }
  }
  /* Back (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Cloned_to_edit Submit & Approved (start) */

  checkPrevClonedState(quo_items) {
    //let { quotation } = this.state
    //let { quotation_items } = quotation
    let prev_state = QUO_STATES.approved.name;
    let issued_item = [];
    quo_items.forEach((elm) => {
      if (elm.issued === true) {
        issued_item.push(elm);
      }
    });
    if (issued_item.length == quo_items.length) {
      prev_state = QUO_STATES.order_issued_completed.name;
    } else if (issued_item.length > 0 && issued_item.length < quo_items.length) {
      prev_state = QUO_STATES.order_issued_partially.name;
    } else {
      prev_state = QUO_STATES.approved.name;
    }
    return {
      prev_state: prev_state,
      issued_item: issued_item,
    };
  }

  onHandleSubmitApprove() {
    let { quotation, clone_from_quo } = this.state;
    let { quotation_items, quotation_item_categories } = quotation;
    let { purchasing_orders } = clone_from_quo;

    let errors = validateQuotation(quotation, QUO_STATES.approved.name);
    if (objectIsEmpty(errors)) {
      errors = validateApproveQuo(quotation);
      if (objectIsEmpty(errors)) {
        quotation.total_sales = calTotalSales(quotation);
        quotation.user_id = this.props.current_user.id;

        quotation.state = QUO_STATES.submitted.name;

        Object.keys(quotation_items).forEach((key) => {
          quotation_items[key].issued = false;
        });

        // Object.keys(quotation_item_categories).forEach((key) => {
        //   quotation_item_categories[key].informed_date =
        //     quotation.approved_state_date;
        // });

        this.setState({ quotation: quotation });

        axios
          .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}`, {
            quotation: this.state.quotation,
          })
          .then((response) => {
            //this.updateFromAfterSaveNoAlert(response.data)
            axios
              .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${quotation.id}/update_state`, {
                state: QUO_STATES.approved.name,
              })
              .then((response) => {
                this.updateFromAfterSave(response.data);

                axios
                  .put(`${process.env.REACT_APP_API_URL}/${API_RESOURCES.quotation}/${this.state.quotation.cloned_from_id}/update_state`, {
                    //update_state
                    state: QUO_STATES.cancelled.name,
                    cancel_note: "ยกเลิกโดยอัตโนมัติ จากการโคลนเพื่อแก้ไข ไปยัง quotation id:" + quotation.id,
                  })
                  .then((response) => {
                    this.setState({ is_loading: false });
                  });
              });
          });

        this.setState({ is_loading: true });
      } else {
        if (errors["no_contracts"]) {
          alert(errors["no_contracts"]);
          this.setState({ errors: errors });
        }
      }
    } else {
      if (errors["suggest_fee"]) {
        alert(errors["suggest_fee"]);
      } else {
        alert("กรุณากรอกข้อมูลให้ครบถ้วน");
        this.setState({ errors: errors });
      }
    }
  }

  /* Cloned_to_edit Submit & Approved (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Action BTN (start) */
  actionBtns() {
    const SaveBtn = () => (
      <button type="button" className="btn btn-primary btn-sm ml-2" onClick={this.onPreviewSubmit}>
        Save
      </button>
    );
    const SaveInformedDateBtn = () => (
      <button type="button" className="btn btn-primary btn-sm ml-2" onClick={this.updateInformedDate}>
        Save
      </button>
    );
    const SubmitBtn = () => {
      if (this.state.selected_quotation_id) {
        return (
          <button type="button" className="btn btn-primary btn-sm ml-2" onClick={(e) => this.onPreviewSubmit(e, QUO_STATES.submitted.name)}>
            Submit
          </button>
        );
      } else {
        return null;
      }
    };
    const SubmitApproveBtn = () => (
      <button type="button" className="btn btn-primary btn-sm ml-2" onClick={(e) => this.onHandleSubmitApprove(e)}>
        Submit and Approve
      </button>
    );

    const ClonedEditCancelBtn = () => (
      <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.onCancelCloneEdit(true)}>
        Cancel
      </button>
    );
    const ApproveBtn = () => (
      // <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => this.onHandleModalApprove(true)}>Approve</button>
      <button type="button" className="btn btn-primary btn-sm ml-2" onClick={(e) => this.onPreviewSubmit(e, QUO_STATES.approved.name)}>
        Approve
      </button>
    );
    const RejectBtn = () => (
      <button type="button" className="btn btn-secondary btn-sm ml-2" onClick={() => this.onHandleRejectModal(true)}>
        Reject
      </button>
    );
    const RejectedBtn = () => (
      <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.onHandleRejectModal(true)}>
        เหตุผล
      </button>
    );
    const CancelBtn = () => {
      if (this.state.selected_quotation_id) {
        return (
          <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.onHandleCancelModal(true)}>
            Cancel
          </button>
        );
      } else {
        return null;
      }
    };
    const CanceledBtn = () => (
      <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.onHandleCancelModal(true)}>
        เหตุผล
      </button>
    );
    const DeleteBtn = () => {
      if (this.state.selected_quotation_id) {
        return (
          <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.onHandleModalDelete(true)}>
            Delete
          </button>
        );
      } else {
        return null;
      }
    };
    const IssuePOBtn = () => (
      <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => this.onHandleIssuePoModal(true)}>
        Issue Order
      </button>
    );

    switch (this.state.quotation.state) {
      case QUO_STATES.cloned_to_edit.name:
        return (
          <React.Fragment>
            <SaveBtn />
            <SubmitApproveBtn />
            <ClonedEditCancelBtn />
          </React.Fragment>
        );
      case QUO_STATES.draft.name:
        return (
          <React.Fragment>
            <SaveBtn />
            <SubmitBtn />
            <CancelBtn />
            <DeleteBtn />
          </React.Fragment>
        );
      case QUO_STATES.rejected.name:
        return (
          <React.Fragment>
            <RejectedBtn />
          </React.Fragment>
        );
      case QUO_STATES.submitted.name:
        return (
          <React.Fragment>
            <SaveBtn />
            <ApproveBtn />
            <RejectBtn />
            <CancelBtn />
          </React.Fragment>
        );
      case QUO_STATES.approved.name:
      case QUO_STATES.order_issued_partially.name:
        return (
          <React.Fragment>
            <SaveInformedDateBtn />
            <IssuePOBtn />
            <CancelBtn />
          </React.Fragment>
        );
      case QUO_STATES.cancelled.name:
        return (
          <React.Fragment>
            <CanceledBtn />
          </React.Fragment>
        );
      default:
        return;
    }
  }
  /* Action BTN (end) */
  /* ========================================================= */

  /* ========================================================= */
  /* Display (start) */
  displayCustomerPage() {
    let { quotation, selected_station, disabled, display_station, randomKey, reFilteringClientStation, reFilteringBuyer, selected_buyer, display_buyer } = this.state;
    let { client_station, buyer } = quotation;
    let station_disabled_bool = selected_station !== "" || disabled;
    let station_disabled = station_disabled_bool ? "disabled" : "";
    let buyer_disabled_bool = !stringIsEmpty(selected_buyer) || disabled;
    let buyer_disabled = buyer_disabled_bool ? "disabled" : "";
    const issuedPoLinks = () => {
      if (quotation.purchasing_orders) {
        return quotation.purchasing_orders.map((po, index) => {
          return (
            <a href={`/${API_RESOURCES.order}/${po.id}`}>
              {index !== 0 ? ", " : ""}
              {po.code} ({po.state})
            </a>
          );
        });
      }
    };
    return (
      <React.Fragment>
        <div className={this.state.current_tab === QUO_TABS.info ? "offset-lg-1 col-lg-10 px-3 block-info" : "d-none"}>
          {/* User Info */}
          <h5 className="pb-1">ข้อมูลทั่วไป</h5>
          <div className="card-box p-3 mt-1">
            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label>เลขที่ใบเสนอราคา</label>
              </div>
              <div className="col mt-2">{quotation.code || "-"}</div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">วันที่ใบเสนอราคา</label>
              </div>
              <div className="col-8 col-md-4 date-picker-wrapper">
                <DatePicker className="form-control" dateFormat={FORMAT_APP_DATE} selected={new Date(this.state.quotation.quotation_date)} onChange={(date) => this.onChangeQuoDate(date)} disabled={disabled} />
                <ErrorMessage errorKey={"quotation_date"} errorMessages={this.state.errors} />
              </div>
            </div>
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">แก้ไขล่าสุด</label>
              </div>
              <div className="col-8 col-md-4 date-picker-wrapper">
                {moment(this.state.quotation.updated_at).format("DD-MM-YYYY")}
              </div>
            </div>

            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label className="sign-is-required">ชื่อผู้อนุมัติ</label>
              </div>
              <div className="col-8 col-md-4 mt-2">
                <input type="id" className="form-control" id="approver_name" name="approver_name" onChange={this.onChangeQuotationDetails} value={this.state.quotation.approver_name} disabled={disabled} required></input>
                <ErrorMessage errorKey={"approver_name"} errorMessages={this.state.errors} />
              </div>
              <div className="col-4 col-md-2 mt-2">
                <label className="sign-is-required">ตำแหน่งชื่อผู้อนุมัติ</label>
              </div>
              <div className="col mt-2">
                <input type="id" className="form-control" id="approver_title" name="approver_title" onChange={this.onChangeQuotationDetails} value={this.state.quotation.approver_title} disabled={disabled} required></input>
                <ErrorMessage errorKey={"approver_title"} errorMessages={this.state.errors} />
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label>ลิงก์ที่ออก Order</label>
              </div>
              <div className="col mt-2">{issuedPoLinks()}</div>
            </div>
            <div className="row">
              <div className="col-4 col-md-2 pr-0 mt-2">
                <label>ผู้รับผิดชอบ</label>
              </div>
              <div className="col-8 col-md-4 date-picker-wrapper">
                <input
                  type="id"
                  className="form-control"
                  onChange={this.onChangeOwner}
                  value={this.props.id !== "new" || this.state.quotation.owner ? this.state.quotation.owner : `${this.props.current_user.firstname ? this.props.current_user.firstname : ""} ${this.props.current_user.lastname ? this.props.current_user.lastname : ""}`}
                  disabled={quotation.state === "Order issued completed"}
                ></input>
              </div>
            </div>
          </div>

          {/* Customer Station Info */}
          <h5 className="mt-4">ข้อมูลสถานี</h5>
          <div className="card-box px-3 pt-3 mt-1">
            <div className="row my-2">
              <div className="col-4 col-md-2 pr-0">
                <label className="sign-is-required">สถานี</label>
              </div>
              <div className="col">
                <ClientStation onChange={this.onClickSelectStation} clientStation={client_station} disabled={disabled || (display_station && !client_station.id)} key={randomKey + reFilteringClientStation} />
              </div>
            </div>
            <a onClick={this.handleDisplayStation}>
              <div className={`row mt-2 ${display_station ? "d-none" : ""}`}>
                <div className="table-item-bg col-12 d-flex justify-content-between bg-antiquewhite cursor-pointer">+ เพิ่มสถานี</div>
              </div>
            </a>
            <div className={`row pb-3 bg-ghostwhite ${display_station ? "" : "d-none"}`}>
              <div className="col">
                <div className="row mt-2">
                  <div className="col-4 col-md-2 pr-0">
                    <label className="sign-is-required">ชื่อสถานี</label>
                  </div>
                  <div className="col">
                    <input type="id" className="form-control" id="customerStationName" name="name" onChange={(e) => this.onChangeCustomerStation(e, FIELD_CLIENT_STATION)} value={client_station.name} disabled={station_disabled}></input>
                    <ErrorMessage errorKey={"client_station.name"} errorMessages={this.state.errors} />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-4 col-md-2 pr-0">
                    <label>ที่อยู่</label>
                  </div>
                  <div className="col">
                    <input
                      type="id"
                      className="form-control"
                      id="quotationStationAddressLine1"
                      name="address_line1"
                      onChange={(e) => this.onChangeCustomerStationAddress(e.target.value, e.target.name, FIELD_CLIENT_STATION)}
                      value={client_station.address.address_line1}
                      disabled={station_disabled}
                    ></input>
                    <ErrorMessage errorKey={"client_station.address.address_line1"} errorMessages={this.state.errors} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4 col-md-2 pr-0 mt-2">
                    <label>รหัสไปรษณีย์</label>
                  </div>
                  <div className="col-8 col-md-4 mt-2">
                    <input type="id" className="form-control" id="quotationStationPostcode" name="postcode" onChange={(e) => this.onChangeCustomerStationAddress(e.target.value, e.target.name, FIELD_CLIENT_STATION)} value={client_station.address.postcode} disabled={station_disabled}></input>
                    <ErrorMessage errorKey={"client_station.address.postcode"} errorMessages={this.state.errors} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4 col-md-2 pr-0 mt-2">
                    <label className="sign-is-required">จังหวัด</label>
                  </div>
                  <div className="col-8 col-md-4 mt-2">
                    <ProvinceSelect onChange={this.onChangeCustomerProvince} provinceId={client_station.address.province_id} key={client_station.address.province_id} disabled={station_disabled_bool} />
                    <ErrorMessage errorKey={"client_station.address.province_id"} errorMessages={this.state.errors} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4 col-md-2 pr-0 mt-2">
                    <label className="sign-is-required">อำเภอ/เขต</label>
                  </div>
                  <div className="col-8 col-md-4 mt-2">
                    <DistrictSelect onChange={this.onChangeCustomerDistrict} districtId={client_station.address.district_id} provinceId={client_station.address.province_id} key={client_station.address.province_id} disabled={station_disabled_bool || client_station.address.province_id === ""} />
                    <ErrorMessage errorKey={"client_station.address.district_id"} errorMessages={this.state.errors} />
                  </div>
                  <div className="col-4 col-md-2 pr-0 mt-2">
                    <label className="sign-is-required">ตำบล/แขวง</label>
                  </div>
                  <div className="col-8 col-md-4 mt-2">
                    <SubDistrictSelect
                      onChange={this.onChangeCustomerSubDistrict}
                      subDistrictId={client_station.address.sub_district_id}
                      districtId={client_station.address.district_id}
                      key={client_station.address.district_id}
                      disabled={station_disabled_bool || client_station.address.district_id === ""}
                    />
                    <ErrorMessage errorKey={"client_station.address.sub_district_id"} errorMessages={this.state.errors} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-4 col-md-2 pr-0 mt-2">
                    <label>Sold-to</label>
                  </div>
                  <div className="col-8 col-md-4 mt-2">
                    <input type="id" className="form-control" id="quotationSoldToCode" name="sold_to_code" onChange={(e) => this.onChangeCustomerStation(e, FIELD_CLIENT_STATION)} value={client_station.sold_to_code} disabled={station_disabled}></input>
                  </div>
                  <div className="col-4 col-md-2 pr-0 mt-2">
                    <label>Ship-to</label>
                  </div>
                  <div className="col-8 col-md-4 mt-2">
                    <input type="id" className="form-control" id="quotationShipToCode" name="ship_to_code" onChange={(e) => this.onChangeCustomerStation(e, FIELD_CLIENT_STATION)} value={client_station.ship_to_code} disabled={station_disabled}></input>
                  </div>
                </div>

                <div className="row">
                  <div className="col-4 col-md-2 pr-0 mt-2">
                    <label>ชื่อผู้ติดต่อ</label>
                  </div>
                  <div className="col-8 col-md-4 mt-2">
                    <input type="id" className="form-control" id="quotationContactPerson" name="contact_person" onChange={(e) => this.onChangeCustomerStation(e, FIELD_CLIENT_STATION)} value={client_station.contact_person} disabled={station_disabled}></input>
                  </div>
                  <div className="col-4 col-md-2 pr-0 mt-2">
                    <label>เบอร์ติดต่อ</label>
                  </div>
                  <div className="col-8 col-md-4 mt-2">
                    <input type="id" className="form-control" id="quotationContactTel" name="contact_tel" onChange={(e) => this.onChangeCustomerStation(e, FIELD_CLIENT_STATION)} value={client_station.contact_tel} disabled={station_disabled}></input>
                  </div>
                </div>
                <div className={`row mt-2 mb-2 ${disabled ? "d-none" : ""}`}>
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-danger btn-sm" onClick={this.handleHideStation} disabled={disabled}>
                      ลบ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Buyer Info */}
          <h5 className="mt-4">ข้อมูลผู้ซื้อ</h5>
          <div className="row">
            <div className="col col-md-4 input-group">
              <div className="mr-2">
                <input
                  type="radio"
                  name="hasBuyerFalse"
                  id="hasBuyerFalse"
                  checked={!quotation.has_buyer}
                  disabled={disabled}
                  onChange={() => {
                    this.onChangeQuoHasBuyer(false);
                  }}
                ></input>
              </div>
              <label className="form-check-label" htmlFor="hasBuyerFalse">
                ผู้ซื้อรายเดียวกับสถานี
              </label>
            </div>
            <div className="col col-md-4 input-group">
              <div className="mr-2">
                <input
                  type="radio"
                  name="hasBuyerTrue"
                  id="hasBuyerTrue"
                  checked={quotation.has_buyer}
                  disabled={disabled}
                  onChange={() => {
                    this.onChangeQuoHasBuyer(true);
                  }}
                ></input>
              </div>
              <label className="form-check-label" htmlFor="hasBuyerTrue">
                เลือกผู้ซื้ออื่น (ระบุ...)
              </label>
            </div>
          </div>
          {quotation.has_buyer ? (
            <div className="card-box px-3 pt-3 mt-1">
              <div className="row my-2">
                <div className="col-4 col-md-2 pr-0">
                  <label className="sign-is-required">ผู้ซื้อ</label>
                </div>
                <div className="col">
                  <BuyerSelect onChange={this.onClickSelectBuyer} buyer={buyer} disabled={disabled || (display_buyer && !buyer.id)} key={randomKey + reFilteringBuyer} />
                </div>
              </div>
              <a onClick={this.handleDisplayBuyer}>
                <div className={`row mt-2 ${display_buyer ? "d-none" : ""}`}>
                  <div className="table-item-bg col-12 d-flex justify-content-between bg-antiquewhite cursor-pointer">+ เพิ่มผู้ซื้อ</div>
                </div>
              </a>
              <div className={`row pb-3 bg-ghostwhite ${display_buyer ? "" : "d-none"}`}>
                <div className="col">
                  <div className="row mt-2">
                    <div className="col-4 col-md-2 pr-0">
                      <label className="sign-is-required">ชื่อผู้ซื้อ</label>
                    </div>
                    <div className="col">
                      <input type="id" className="form-control" id="customerBuyerName" name="name" onChange={(e) => this.onChangeCustomerStation(e, FIELD_BUYER)} value={buyer.name} disabled={buyer_disabled}></input>
                      <ErrorMessage errorKey={"buyer.name"} errorMessages={this.state.errors} />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-4 col-md-2 pr-0">
                      <label>ที่อยู่</label>
                    </div>
                    <div className="col">
                      <input type="id" className="form-control" id="quotationBuyerAddressLine1" name="address_line1" onChange={(e) => this.onChangeCustomerStationAddress(e.target.value, e.target.name, FIELD_BUYER)} value={buyer.address.address_line1} disabled={buyer_disabled}></input>
                      <ErrorMessage errorKey={"buyer.address.address_line1"} errorMessages={this.state.errors} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 col-md-2 pr-0 mt-2">
                      <label>รหัสไปรษณีย์</label>
                    </div>
                    <div className="col-8 col-md-4 mt-2">
                      <input type="id" className="form-control" id="quotationBuyerPostcode" name="postcode" onChange={(e) => this.onChangeCustomerStationAddress(e.target.value, e.target.name, FIELD_BUYER)} value={buyer.address.postcode} disabled={buyer_disabled}></input>
                      <ErrorMessage errorKey={"buyer.address.postcode"} errorMessages={this.state.errors} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 col-md-2 pr-0 mt-2">
                      <label className="sign-is-required">จังหวัด</label>
                    </div>
                    <div className="col-8 col-md-4 mt-2">
                      <ProvinceSelect onChange={this.onChangeBuyerProvince} provinceId={buyer.address.province_id} key={buyer.address.province_id} disabled={buyer_disabled_bool} />
                      <ErrorMessage errorKey={"buyer.address.province_id"} errorMessages={this.state.errors} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 col-md-2 pr-0 mt-2">
                      <label className="sign-is-required">อำเภอ/เขต</label>
                    </div>
                    <div className="col-8 col-md-4 mt-2">
                      <DistrictSelect onChange={this.onChangeBuyerDistrict} districtId={buyer.address.district_id} provinceId={buyer.address.province_id} key={buyer.address.province_id} disabled={buyer_disabled_bool || buyer.address.province_id === ""} />
                      <ErrorMessage errorKey={"buyer.address.district_id"} errorMessages={this.state.errors} />
                    </div>
                    <div className="col-4 col-md-2 pr-0 mt-2">
                      <label className="sign-is-required">ตำบล/แขวง</label>
                    </div>
                    <div className="col-8 col-md-4 mt-2">
                      <SubDistrictSelect onChange={this.onChangeBuyerSubDistrict} subDistrictId={buyer.address.sub_district_id} districtId={buyer.address.district_id} key={buyer.address.district_id} disabled={buyer_disabled_bool || buyer.address.district_id === ""} />
                      <ErrorMessage errorKey={"buyer.address.sub_district_id"} errorMessages={this.state.errors} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 col-md-2 pr-0 mt-2">
                      <label>Sold-to</label>
                    </div>
                    <div className="col-8 col-md-4 mt-2">
                      <input type="id" className="form-control" id="quotationBuyerSoldToCode" name="sold_to_code" onChange={(e) => this.onChangeCustomerStation(e, FIELD_BUYER)} value={buyer.sold_to_code} disabled={buyer_disabled}></input>
                    </div>
                    <div className="col-4 col-md-2 pr-0 mt-2">
                      <label>Ship-to</label>
                    </div>
                    <div className="col-8 col-md-4 mt-2">
                      <input type="id" className="form-control" id="quotationBuyerShipToCode" name="ship_to_code" onChange={(e) => this.onChangeCustomerStation(e, FIELD_BUYER)} value={buyer.ship_to_code} disabled={buyer_disabled}></input>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 col-md-2 pr-0 mt-2">
                      <label>ชื่อผู้ติดต่อ</label>
                    </div>
                    <div className="col-8 col-md-4 mt-2">
                      <input type="id" className="form-control" id="quotationBuyerContactPerson" name="buyer_contact_person" onChange={(e) => this.onChangeCustomerStation(e, FIELD_BUYER)} value={buyer.buyer_contact_person} disabled={buyer_disabled}></input>
                    </div>
                    <div className="col-4 col-md-2 pr-0 mt-2">
                      <label>เบอร์ติดต่อ</label>
                    </div>
                    <div className="col-8 col-md-4 mt-2">
                      <input type="id" className="form-control" id="quotationBuyerTel" name="buyer_tel" onChange={(e) => this.onChangeCustomerStation(e, FIELD_BUYER)} value={buyer.buyer_tel} disabled={buyer_disabled}></input>
                    </div>
                  </div>
                  <div className={`row mt-2 mb-2 ${disabled ? "d-none" : ""}`}>
                    <div className="col-12 text-right">
                      <button type="button" className="btn btn-danger btn-sm" onClick={this.handleHideBuyer} disabled={disabled}>
                        ลบ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* Note */}
          <h5 className="mt-4 pb-1">หมายเหตุ</h5>
          <div className="card-box p-3 mt-1">
            <div className="row">
              <div className="col">
                <textarea type="id" className="form-control" id="note" name="note" rows={5} onChange={this.onChangeQuotationDetails} value={this.state.quotation.note} disabled={disabled}></textarea>
              </div>
            </div>
          </div>
          <div className="block-info-buffer-box"></div>
        </div>
      </React.Fragment>
    );
  }

  filterItemSelect(new_item_option = null) {
    const { item_options } = this.state;
    let filter_item_option = [];

    const item_options_choice = new_item_option ? new_item_option : item_options;

    item_options_choice.forEach((option) => {
      filter_item_option.push(option);
    });

    this.setState({ filter_item_selected: filter_item_option });
    // return filter_item_option
  }

  setCheckDuplicateItem(state) {
    this.setState({ check_duplicate: state })
  }

  displayItemsPage() {
    if (this.state.current_tab === QUO_TABS.items) {
      return (
        <React.Fragment>
          <QuotationFormItems
            setCheckDuplicateItem={this.setCheckDuplicateItem}
            check_duplicate={this.state.check_duplicate}
            selectSubItemList={this.state.select_sub_item_list}
            filterItemSelect={this.filterItemSelect}
            onMatchSubItemList={this.onMatchSubItemList}
            onSelectExistingAddTemplate={this.onSelectExistingAddTemplate}
            selected_quotation_id={this.state.selected_quotation_id}
            ItemSelect={this.state.filter_item_selected}
            quotation={this.state.quotation}
            onAddItem={this.onAddItem}
            current_user={this.props.current_user}
            onAddItems={this.onAddItems}
            onDeleteItem={this.onDeleteItem}
            onChangeItemDetails={this.onChangeItemDetails}
            onChangeCategoryName={this.onChangeCategoryName}
            onChangeShippingDetails={this.onChangeShippingDetails}
            onChangeRemarkDetail={this.onChangeRemarkDetail}
            onAddShippingCost={this.onAddShippingCost}
            onDeleteShippingCost={this.onDeleteShippingCost}
            onResetFee={this.onResetFee}
            key={this.state.randomKey}
            disabled={this.state.disabled}
            selected_dc_item={this.state.selected_dc_item}
            selected_vendor_item={this.state.selected_vendor_item}
            witholding_tax={this.state.witholding_tax}
            changeWithOutHoldingTax={this.changeWithOutHoldingTax}
            zone_type={this.state.zone_type}
          />
        </React.Fragment>
      );
    }
  }

  getExcelVendorData(quotation) {
    try {
      let response = quotation;
      let cats = [];
      let catObj = [];
      if (response.quotation_item_categories) {
        let categories = response.quotation_item_categories;
        let filterVendor = _.filter(categories, (category) => {
          return category.item_category.dc_id === 0;
        });
        cats = _.map(filterVendor, (item) => item.item_category_id);
        catObj = filterVendor;
        let filter = _.filter(response.quotation_items, (item) => {
          return cats.includes(item.item_category_id);
        });
        response.quotation_items = filter;
        response.purchasing_orders = _.filter(response.purchasing_orders, (item) => {
          return cats.includes(item.item_category_id);
        });
        response.quotation_item_categories = _.filter(response.quotation_item_categories, (item) => {
          return cats.includes(item.item_category_id);
        });
      }

      let excelData = response;

      return excelData;
    } catch (e) {
      return quotation;
    }
  }

  displayPurchasePricePage() {
    if (this.state.current_tab === QUO_TABS.purchase_prices) {
      // this.getExcelVendorData(this.state.quotation)
      return (
        <React.Fragment>
          <QuotationPurchasePrices
            ItemSelect={this.state.item_options}
            quotation={this.state.quotation}
            onAddItem={this.onAddItem}
            onDeleteItem={this.onDeleteItem}
            onChangeItemDetails={this.onChangeItemDetails}
            onChangeCategoryName={this.onChangeCategoryName}
            onChangeShippingDetails={this.onChangeShippingDetails}
            onAddShippingCost={this.onAddShippingCost}
            onDeleteShippingCost={this.onDeleteShippingCost}
            onResetContractPrice={this.onResetContractPrice}
            onChangeQuoContractDate={this.onChangeQuoContractDate}
            onReCalContractDate={this.onReCalContractDate}
            onChangeContract={this.onChangeContract}
            disabled={this.state.disabled}
            key={this.state.randomKey}
            reset_pp_key={this.state.reset_pp_key}
            errors_pprices={this.state.errors_pprices}
            selected_dc_item={this.state.selected_dc_item}
            selected_vendor_item={this.state.selected_vendor_item}
          />
        </React.Fragment>
      );
    }
  }

  displayManufacturePage() {
    if (this.state.current_tab === QUO_TABS.manufacture) {
      return (
        <React.Fragment>
          <div>
            <QuotationManufacture
              updateInformedDate={this.updateInformedDate}
              quotation={this.state.quotation}
              onChangeCategoryInformedDate={this.onChangeCategoryInformedDate}
              onGetWarrantyCodes={this.onGetWarrantyCodes}
              onDeleteWarrantyCodes={this.onDeleteWarrantyCodes}
              onChangeItemManufactureDetails={this.onChangeItemManufactureDetails}
              disabled={this.state.disabled}
              isApproved={[QUO_STATES.approved.name, QUO_STATES.order_issued_partially.name, QUO_STATES.order_issued_completed.name, QUO_STATES.cloned_to_edit.name].includes(this.state.quotation.state)} // check whether in state approved and afterward
              selected_dc_item={this.state.selected_dc_item}
              selected_vendor_item={this.state.selected_vendor_item}
            />
          </div>
        </React.Fragment>
      );
    }
  }

  displayDocumentsPage() {
    if (this.state.current_tab === QUO_TABS.documents) {
      return (
        <React.Fragment>
          <div className="offset-lg-1 col-lg-10 px-3">
            <QuotationFormDocuments zone_type={this.state.zone_type} quotation={this.state.quotation} quotation_state={this.state.quotation.state} witholding_tax={this.state.witholding_tax} />
          </div>
        </React.Fragment>
      );
    }
  }

  cancelQuotation() {
    if (this.state.is_canceling) {
      let { quotation } = this.state;
      let isIssuedPartiallyState = quotation.state === QUO_STATES.order_issued_partially.name;
      let disable_cancel_modal = quotation.state === QUO_STATES.cancelled.name;
      let warning = isIssuedPartiallyState ? (
        <div>
          <span className="font-weight-bold color-success">[กรณียกเลิกทั้งหมด]</span>
          <br />
          Order ที่เกี่ยวข้อง จะถูกยกเลิกทั้งหมดด้วย
          <br />
          <span className="font-weight-bold color-red">[กรณียกเลิกสินค้าที่เหลืออยู่]</span>
          <br />
          และถ้ามีสินค้าที่ออกไปแล้วในหมวดหมู่เดียวกัน อาจจะทำให้ค่าบริการไม่ถูกต้องได้
        </div>
      ) : (
        ""
      );
      const content = (cancel_note) => (
        <React.Fragment>
          <div className={`row ${isIssuedPartiallyState ? "" : "d-none"}`}>
            <div className="col-12">
              <p>{warning}</p>
            </div>
          </div>
          <div className="row pb-2 mb-2">
            <div className="col-12 col-md-4 pt-2">
              <label className="sign-is-required">เหตุผล</label>
            </div>
            <div className="col pt-2">
              <textarea className="form-control" type="text" name="cancel_note" defaultValue={this.state.disabled ? cancel_note : ""} onChange={this.onChangeQuotationDetails} rows="4" disabled={disable_cancel_modal}></textarea>
            </div>
          </div>
        </React.Fragment>
      );
      return (
        <ModalConfirmation
          isOpen={true}
          title="ยกเลิกใบเสนอราคา"
          content={content(quotation.cancel_note)}
          btnDanger={isIssuedPartiallyState ? "ยกเลิกสินค้าที่เหลือ" : undefined}
          onConfirm={disable_cancel_modal ? undefined : this.onCancel}
          onCancel={() => this.onHandleCancelModal(false)}
          onCustomAction={isIssuedPartiallyState ? this.onCancelItemsModal : undefined}
        />
      );
    }
  }

  getSubItems(items, item_id) {
    const find_sub_item = items.filter(({ parent_item_id }) => parent_item_id === item_id);
    return find_sub_item;
  }

  getTemplateItems() {
    const { quotation } = this.state;
    let items = [];
    let item_index = 0;

    Object.keys(quotation.quotation_item_categories).forEach((key, index) => {
      let sub_index = 0;
      items.push({
        key: index,
        style: {
          marginBottom: 10,
          background: "var(--lightblue03)",
          borderRadius: 5,
          border: "none",
        },
        label: <div style={{ fontSize: 18, fontWeight: "bold" }}>{quotation.quotation_item_categories[key].sys_name}</div>,
        children: quotation.quotation_items.map((item, i_index) => {
          if (quotation.quotation_item_categories[key].item_category_id === item.item_category_id && !item.from_parent) item_index = item_index + 1;
          if (item.from_parent) sub_index = sub_index + 1;

          return (
            <div style={{ paddingLeft: 15 }}>
              {i_index === 0 && (
                <div style={{ display: "grid", gridTemplateColumns: "70% auto", gap: 10, marginBottom: 5 }}>
                  <b style={{ fontSize: 18 }}>ชื่อสินค้า</b>
                  <center>
                    <b style={{ fontSize: 18 }}>จำนวน</b>
                  </center>
                </div>
              )}
              {quotation.quotation_item_categories[key].item_category_id === item.item_category_id && !item.from_parent && (
                <div style={{ marginBottom: 10, fontSize: 16, paddingLeft: 10 }}>
                  <div style={{ marginBottom: 5 }}>
                    <div style={{ display: "grid", gridTemplateColumns: "70% auto", gap: 10 }}>
                      <div>
                        {item_index}. {item.name}
                      </div>
                      <center>
                        {item.qty} {item.unit}
                      </center>
                    </div>
                  </div>
                  {this.getSubItems(quotation.quotation_items, item.item_id).map((item, sub_index) => {
                    return (
                      <div style={{ marginBottom: 5, paddingLeft: 20 }}>
                        <div style={{ display: "grid", gridTemplateColumns: "70% auto", gap: 10 }}>
                          <div>
                            {item_index}.{sub_index + 1} {item.name}
                          </div>
                          <center>
                            {item.qty} {item.unit}
                          </center>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }),
      });
    });

    return items;
  }

  async handleSubmitTemplate(values) {
    this.setState({ is_loading: true });
    const { quotation } = this.state;
    let quotation_items = [];

    await quotation.quotation_items.forEach((item) => {
      quotation_items.push({
        from_parent: item?.from_parent ? item?.from_parent : false,
        from_parent_item_id: item?.parent_item_id ? item?.parent_item_id : null,
        category_name: item?.item_category?.sys_name,
        name: item.name,
        item_category_id: item.item_category_id,
        item_id: item.item_id,
        qty: item.qty,
      });
    });

    const params = { template_name: values.name, template_created: `${this.props.current_user.firstname || ""} ${this.props.current_user.lastname || ""}`, quotation_items: quotation_items };

    axios
      .post(`${process.env.REACT_APP_API_URL}/quotation_template`, params)
      .then((response) => {
        this.setState({ is_add_to_template: false });
        this.refTemplate.current.resetFields();
        this.getQuotationTemplate();
        // this.setState({ is_loading: false });
      })
      .catch((err) => {
        this.setState({ is_loading: false });
        console.log("err", err);
      });
  }

  searchTemplateName(value) {
    const { quotation_template_list } = this.state;
    let search_list = [];

    if (value) this.setState({ is_quotation_template_search: true });
    else {
      this.setState({ is_quotation_template_search: false, quotation_template_search_list: [] });
      return;
    }

    quotation_template_list.forEach((template) => {
      const is_match = template.name.includes(value);

      if (is_match) {
        search_list.push(template);
      }
    });

    this.setState({ quotation_template_search_list: search_list });
  }

  getQuotationTemplate(is_loading = true) {
    this.setState({ is_loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/quotation_template`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ quotation_template_list: response.data });

          if (is_loading) this.setState({ is_loading: false });
        }
      })
      .catch((err) => {
        this.setState({ is_loading: false });
        console.log("err", err);
      });
  }

  getTemplateCategoryGroup(quotation_template_items) {
    let category = [];

    quotation_template_items.forEach((item) => {
      const findIndex = category.findIndex((cate) => cate.item_category_id === item.item_category_id);
      const newCategoryParams = {
        category_name: item.category_name,
        item_category_id: item.item_category_id,
        items: [item],
      };

      if (findIndex !== -1) {
        category[findIndex].items.push(item);
      } else {
        category.push(newCategoryParams);
      }
    });

    return category;
  }

  onDeleteTemplateQuotation(id) {
    this.setState({ is_loading: true });
    axios
      .delete(`${process.env.REACT_APP_API_URL}/quotation_template/${id}`)
      .then((response) => {
        this.setState({ is_loading: false });
        this.getQuotationTemplate();
      })
      .catch((err) => {
        this.setState({ is_loading: false });
        console.log("err", err);
      });
  }

  onPreDeleteTemplate(template) {
    return Modal.confirm({
      onOk: () => this.onDeleteTemplateQuotation(template.id),
      title: "ลบ Template.",
      icon: <ExclamationCircleOutlined />,
      content: `คุณต้องการลบ Template "${template.name}" ใช่หรือไม่ ?`,
      okText: "ใช่, ลบ Template",
      cancelText: "ไม่ใช่",
    });
  }

  handleAddTemplateItems(template) {
    this.setState({ is_loading: true });
    const { item_options, quotation, select_sub_item_list, selected_item_options } = this.state;
    let items = [];
    let sub_items = [...select_sub_item_list];

    template.quotation_template_items.forEach((item) => {
      if (item.from_parent) {
        sub_items.push({
          sub_item_id: item.item_id,
          parent_item_id: item.from_parent_item_id,
        });
      } else {
        let findItemOptions = item_options.find(({ id }) => id === item.item_id);

        if (!findItemOptions) findItemOptions = selected_item_options.find(({ id }) => id === item.item_id);

        let findDuplicate = quotation.quotation_items.find(({ item_id }) => item_id === item.item_id);
        let find_sub_items = template.quotation_template_items.filter(({ from_parent_item_id }) => from_parent_item_id === item.item_id);

        if ((findItemOptions && !findDuplicate) || (findDuplicate && findDuplicate.is_parent)) {
          findItemOptions.qty = item.qty;
          if (find_sub_items.length > 0) findItemOptions["sub_items"] = find_sub_items;

          items.push(findItemOptions);
        }
      }
    });

    sub_items = this.onMatchSubItemList(sub_items);

    this.template_search_ref.current.setFieldsValue({ template_name_search: null });
    this.setState({ select_sub_item_list: sub_items, is_show_add_template: false, is_quotation_template_search: false, quotation_template_search_list: [] });

    if (items.length > 0) this.onAddItems(items);
    else this.setState({ is_loading: false });
  }

  getTemplateList() {
    let show_template_data = [];

    const { quotation_template_list, quotation_template_search_list, is_quotation_template_search } = this.state;

    show_template_data = is_quotation_template_search ? quotation_template_search_list : quotation_template_list;

    let items = [];
    show_template_data.forEach((template, index) => {
      let item_index = 0;
      items.push({
        key: index,
        style: {
          marginBottom: 10,
          background: "var(--lightblue03)",
          borderRadius: 5,
          border: "none",
        },
        label: (
          <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <b style={{ fontSize: 18 }}>
              {template.name} ({template.created_by || ""})
            </b>
            <div style={{ display: "flex", gap: 10, alignItems: "center", justifyContent: "center" }}>
              <div className="select-template-btn" onClick={() => this.handleAddTemplateItems(template)}>
                <CheckCircleOutlined /> เลือก
              </div>
              {this.props.current_user.is_admin && (
                <div onClick={() => this.onPreDeleteTemplate(template)} className="cancel-template-btn" style={{ padding: "3px 10px" }}>
                  <DeleteOutlined /> ลบ
                </div>
              )}
            </div>
          </div>
        ),
        children: (
          <Collapse
            bordered={false}
            style={{ backgroundColor: "unset" }}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            items={this?.getTemplateCategoryGroup(template.quotation_template_items)?.map((category, c_index) => {
              return {
                key: c_index,
                label: <b style={{ fontSize: 16 }}>{category.category_name}</b>,
                style: {
                  marginBottom: 10,
                  background: "white",
                  borderRadius: 5,
                  border: "none",
                },
                children: category.items.map((item, i_index) => {
                  item_index = item_index + 1;
                  return (
                    <div key={i_index}>
                      {i_index === 0 && (
                        <div style={{ display: "grid", gridTemplateColumns: "70% auto", gap: 10, marginBottom: 5 }}>
                          <b style={{ fontSize: 16 }}>ชื่อสินค้า</b>
                          <center>
                            <b style={{ fontSize: 16 }}>จำนวน</b>
                          </center>
                        </div>
                      )}
                      <div style={{ paddingLeft: 15 }}>
                        {!item.from_parent && (
                          <div style={{ marginBottom: 5 }}>
                            <div style={{ display: "grid", gridTemplateColumns: "70% auto", gap: 10 }}>
                              <div>
                                {item_index}. {item.name}
                              </div>
                              <center>
                                {item.qty} {item.unit}
                              </center>
                            </div>
                          </div>
                        )}
                        {category?.items
                          ?.filter((c_item) => c_item.from_parent_item_id === item.item_id)
                          ?.map((sub_item, s_index) => {
                            return (
                              <div style={{ marginBottom: 5, paddingLeft: 15 }}>
                                <div style={{ display: "grid", gridTemplateColumns: "70% auto", gap: 10 }}>
                                  <div>
                                    {item_index}.{s_index + 1}. {sub_item.name}
                                  </div>
                                  <center>
                                    {sub_item.qty} {sub_item.unit}
                                  </center>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                }),
              };
            })}
          />
        ),
      });
    });

    return items;
  }

  onSelectExistingAddTemplate() {
    this.setState({ is_show_add_template: true });
  }

  displayModalSelectFromTemplate() {
    const { is_show_add_template, quotation_template_list } = this.state;

    return (
      <div>
        <Modal
          open={is_show_add_template}
          width={"80vw"}
          centered
          footer={false}
          onCancel={() => {
            this.setState({ is_show_add_template: false });
          }}
        >
          <div style={{ minHeight: 500 }}>
            <h4 style={{ marginBottom: 10 }}>รายการ Template</h4>
            <div style={{ display: "flex", gap: 10, alignItems: "center", justifyContent: "center" }}>
              <Form ref={this.template_search_ref} style={{ width: "100%" }}>
                <Form.Item name="template_name_search" style={{ width: "100%" }}>
                  <Input style={{ width: "100%" }} placeholder="ค้นหาชื่อ Template" onChange={(e) => this.searchTemplateName(e.target.value)} />
                </Form.Item>
              </Form>
            </div>
            {quotation_template_list.length > 0 ? (
              <div style={{ maxHeight: "70vh", overflow: "hidden scroll" }}>
                <Collapse bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} items={this.getTemplateList()} style={{ backgroundColor: "unset" }} />
              </div>
            ) : (
              <Empty description={<b style={{ fontSize: 18, margin: "20px 0px" }}>ไม่มี Template ที่บันทึกไว้</b>} />
            )}
          </div>
        </Modal>
      </div>
    );
  }

  displayModalAddToTemplate() {
    const { is_add_to_template } = this.state;

    return (
      <Modal width={"80vw"} open={is_add_to_template} centered footer={false} closable={false} onCancel={() => this.setState({ is_add_to_template: false })}>
        <h5>เพิ่มรายการ Template</h5>
        <Form ref={this.refTemplate} onFinish={this.handleSubmitTemplate} layout="vertical">
          <Form.Item label="ชื่อ Template" name="name" rules={[{ required: true, message: "กรุณากรอกชื่อ Template !" }]}>
            <Input style={{ width: "100%" }} placeholder="กรอกชื่อ Template" />
          </Form.Item>
          <Form.Item>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", gap: 10 }}>
              <Button type="primary" htmlType="submit" className="save-template-btn">
                บันทึก Template
              </Button>
              <Button type="button" className="cancel-template-btn" onClick={() => this.setState({ is_add_to_template: false })}>
                ยกเลิก
              </Button>
            </div>
          </Form.Item>

          <h5>รายการภายใน Template</h5>
          <div style={{ maxHeight: "55vh", overflow: "hidden scroll" }}>
            <div style={{ padding: "0px 10px" }}>
              <Collapse bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} items={this.getTemplateItems()} style={{ backgroundColor: "unset" }} />
            </div>
          </div>
        </Form>
      </Modal>
    );
  }

  displaySaveDuplicate() {
    const { show_save_duplicate, duplicate_list } = this.state
    return <Modal width={800} open={show_save_duplicate} footer={false} centered onCancel={() => this.setState({ show_save_duplicate: false })}>
      <div style={{ display: "flex", gap: 10, alignItems: "end", justifyContent: "center" }}>
        <ExclamationCircleFilled style={{ color: "orange", fontSize: 32 }} /> <h4 style={{ margin: 0 }}>รายการสินค้าซ้ำกัน</h4>
      </div>
      <Divider style={{ margin: "15px 0px" }} />
      <br />
      <center>
        <h5 style={{ marginTop: -15 }}>เนื่องจากมีรายการสินค้าที่ซ้ำกัน, คุณต้องการบันทึกใบเสนอราคาใช่หรือไม่ ?</h5>
      </center>
      <div style={{ fontWeight: "bold", marginTop: 15, textDecoration: "underline", marginBottom: 10, fontSize: 16 }}>รายการที่ซ้ำกัน</div>
      <ul>
        {duplicate_list.map((item) => {
          console.log('item', item)
          return !item.is_parent && <li><a href={`/quotations/${item.quotation_duplicate_id}`} target={'_blank'}>{item.item_category.dc_id === 0 ? `(Vendor)` : `(DC)`} {item.name}</a></li>
        })}
      </ul>

      <footer style={{ display: "flex", gap: 10 }}>
        <Button style={{ background: "none", color: "red", borderColor: "red", width: "100%" }} onClick={() => this.setState({ show_save_duplicate: false })}>ไม่ใช่</Button>
        <Button style={{ background: "green", color: "white", width: "100%" }} onClick={() => this.onHandleSubmit(this.state.temp_stage_save.e, this.state.temp_stage_save.newState)}>ใช่, บันทึก</Button>
      </footer>
    </Modal>
  }

  rejectQuotation() {
    if (this.state.is_rejecting) {
      let { quotation } = this.state;
      let disable_reject_modal = quotation.state === QUO_STATES.rejected.name;
      const content = (cancel_note) => (
        <React.Fragment>
          <div className="row pb-2 mb-2">
            <div className="col-12 col-md-4 pt-2">
              <label className="sign-is-required">เหตุผล</label>
            </div>
            <div className="col pt-2">
              <textarea className="form-control" type="text" name="cancel_note" defaultValue={this.state.disabled ? cancel_note : ""} onChange={this.onChangeQuotationDetails} rows="4" disabled={disable_reject_modal}></textarea>
            </div>
          </div>
        </React.Fragment>
      );
      return <ModalConfirmation isOpen={true} title="ปฏิเสธใบเสนอราคา" content={content(quotation.cancel_note)} onConfirm={disable_reject_modal ? undefined : this.onReject} onCancel={() => this.onHandleRejectModal(false)} />;
    }
  }
  /* Display (end) */
  /* ========================================================= */

  render() {
    let { current_tab } = this.state;

    let stateColor = "warning";
    if (this.state.quotation) {
      switch (this.state.quotation.state) {
        case QUO_STATES.cancelled.name:
        case QUO_STATES.rejected.name:
          stateColor = "danger";
          break;
        case QUO_STATES.approved.name:
        case QUO_STATES.order_issued_partially.name:
        case QUO_STATES.order_issued_completed.name:
          stateColor = "success";
        default:
      }
    }
    const display_doc_tab = () => {
      if (this.state.selected_quotation_id !== null) {
        return (
          <Tooltip placement="top" title={this.state.quotation.client_station.name ? "" : "กรุณาเลือกสถานี !"}>
            <li className={`nav-item ${!this.state.quotation.client_station.name ? "disabled-tabs" : ""}`}>
              <a className={`nav-link px-2 ${current_tab === QUO_TABS.documents ? "active" : ""}`} onClick={() => this.state.quotation.client_station.name && this.onChangeFormTab(QUO_TABS.documents)}>
                Preview and Summary
              </a>
            </li>
          </Tooltip>
        );
      } else {
        return null;
      }
    };

    if (!this.state.is_mounted) {
      return <Spinner loading={this.state.is_loading} allCategory={this.state.cate_length} allLoadeCategory={this.state.load_cate} />;
    } else {
      return (
        <React.Fragment>
          <Spinner loading={this.state.is_loading} allCategory={this.state.cate_length} allLoadeCategory={this.state.load_cate} />
          <div className="pb-2 px-3">
            <div className="row">
              <div className="col-9 pb-2 pr-0 d-flex flex-row flex-wrap align-items-end">
                <span className="header-title d-none d-md-block my-0">{APP_TITLE_QUO}</span>
                {this.state.quotation.code ? <h5 className="my-0 pl-0 pl-md-2 pr-2 color-darkblue">{this.state.quotation.code}</h5> : null}
                <div className="d-flex align-items-end mb-1">
                  <span className={`py-1 px-2 badge badge-pill badge-${stateColor} quo-state-tag text-truncate`}>{this.state.quotation.state} </span>
                </div>
              </div>
              <div className="col-3 text-right">
                <button type="button" className="btn btn-outline-primary btn-sm" onClick={this.onClickBack}>
                  Back
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 pt-2 pt-md-0">
                <ul className="nav nav-tabs tab-responsive div-hide-scrollbar">
                  <li className="nav-item">
                    <a className={`nav-link px-2 ${current_tab === QUO_TABS.info ? "active" : ""}`} onClick={() => this.onChangeFormTab(QUO_TABS.info)}>
                      ทั่วไป
                    </a>
                  </li>
                  <Tooltip placement="top" title={this.state.quotation.client_station.name ? "" : "กรุณาเลือกสถานี !"}>
                    <li className={`nav-item ${!this.state.quotation.client_station.name ? "disabled-tabs" : ""}`}>
                      <a className={`nav-link px-2 ${current_tab === QUO_TABS.items ? "active" : ""}`} onClick={() => this.state.quotation.client_station.name && this.onChangeFormTab(QUO_TABS.items)}>
                        สินค้า
                      </a>
                    </li>
                  </Tooltip>
                  <Tooltip placement="top" title={this.state.quotation.client_station.name ? "" : "กรุณาเลือกสถานี !"}>
                    <li className={`nav-item ${!this.state.quotation.client_station.name ? "disabled-tabs" : ""}`}>
                      <a className={`nav-link px-2 ${current_tab === QUO_TABS.purchase_prices ? "active" : ""}`} onClick={() => this.state.quotation.client_station.name && this.onChangeFormTab(QUO_TABS.purchase_prices)}>
                        ราคาขาซื้อ
                      </a>
                    </li>
                  </Tooltip>
                  <Tooltip placement="top" title={this.state.quotation.client_station.name ? "" : "กรุณาเลือกสถานี !"}>
                    <li className={`nav-item ${!this.state.quotation.client_station.name ? "disabled-tabs" : ""}`}>
                      <a className={`nav-link px-2 ${current_tab === QUO_TABS.manufacture ? "active" : ""}`} onClick={() => this.state.quotation.client_station.name && this.onChangeFormTab(QUO_TABS.manufacture)}>
                        แจ้งผู้ผลิต
                      </a>
                    </li>
                  </Tooltip>
                  {display_doc_tab()}
                </ul>
              </div>

              <div className="col-12 col-md-6 mt-2">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                  {this.state.current_tab === QUO_TABS.items && this.state.quotation.quotation_items.length > 0 && this.props.current_user.is_admin && (
                    <div className="add-to-template-btn" onClick={() => this.setState({ is_add_to_template: true })}>
                      <StarFilled /> เพิ่มไปที่ Template
                    </div>
                  )}
                  <div className="pl-1 text-right">{this.actionBtns()}</div>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={this.handleSubmit}>
            {this.displayCustomerPage()}
            {this.displayDocumentsPage()}
            {this.displayItemsPage()}
            {this.displayPurchasePricePage()}
            {this.displayManufacturePage()}
            {this.displayDeleteConfirmation()}
            {this.displayApproveConfirmation()}
            {this.issuePo()}
            {this.cancelQuotation()}
            {this.rejectQuotation()}
            {this.displayBackConfirmation()}
            {this.displayModalAddToTemplate()}
            {this.displayModalSelectFromTemplate()}
            {this.displaySaveDuplicate()}
          </form>
        </React.Fragment>
      );
    }
  }
}
export default withRouter(QuotationForm);
