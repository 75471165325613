import React from "react";
import Modal from "react-modal";
import _ from "lodash";

import { MODAL_STYLE_SMALL_SM, MODAL_STYLE_LARGE, QUO_STATES } from "../../constants";
import { QuotationFormItemsShippingCost } from "./QuotationFormItemsShippingCost";
import { itemCategoryName } from "../../models/ItemCategories";
import { calTotalSalesWithVat, calTotalDiscount, getCategoryTotalExpense, calTotalSales, getCateSuggestedFee } from "../../models/Quotations";
import AddItem from "./form/AddItem";
import Number from "../global/Number";
import TooltipExclamationMark from "../global/TooltipExclamationMark";
import ModalConfirmation from "../global/ModalConfirmation";

import { Multiple } from "../../functions/Number";
import { solveFeeNew } from "../../functions/FeePurchaseCalculation";
import { objectIsEmpty, queryInArr } from "../../functions/Object";
import { uniqueKey } from "../../functions/Random";
import { QuotationFormItemModal } from "./QuotationFormItemModal";
import { PlusOutlined, BlockOutlined } from "@ant-design/icons";
import QuotationModalDuplicate from "./QuotationModalDuplicate";

const QUO_ITEM_LIST_TABS = {
  vendor: 0,
  dc: 1,
};

export class QuotationFormItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_item_list_tab: QUO_ITEM_LIST_TABS.vendor,
      multi_items: [],
      multi_item_cate_ids: {},
      selected_item: "",
      selected_item_category: "",
      edited_item: null,
      custom_item: "",
      custom_quantity: "",
      quantity: "",
      comment: "",
      modal_preview: false,
      modal_multi_add: false,
      modal_multi_add_query: "",
      isConfirmingAdjustment: false,
      is_completed: props.quotation.state === QUO_STATES.order_issued_completed.name,
      selected_dc_item: 0,
      selected_vendor_item: 0,

      select_multi_item_vendor: 0,
      select_multi_item_dc: 0,

      sub_items_selected: [],

      is_show_duplicate_modal: false,
      item_duplicate_list: [],
    };

    this.handleOpenModalPreview = this.handleOpenModalPreview.bind(this);
    this.handleCloseModalPreview = this.handleCloseModalPreview.bind(this);
    this.handleOpenEditedModal = this.handleOpenEditedModal.bind(this);
    this.handleCloseEditedModal = this.handleCloseEditedModal.bind(this);
    this.handleOpenMultiAddItemsModal = this.handleOpenMultiAddItemsModal.bind(this);
    this.handleCloseMultiAddItemsModal = this.handleCloseMultiAddItemsModal.bind(this);
    this.onAddMultiItems = this.onAddMultiItems.bind(this);
    this.onSearchMultiAddItems = this.onSearchMultiAddItems.bind(this);
    this.onClickMultiItemCategory = this.onClickMultiItemCategory.bind(this);
    this.onClickMultiItemHideAll = this.onClickMultiItemHideAll.bind(this);
    this.onClickMultiItemCate = this.onClickMultiItemCate.bind(this);
    this.onClickMultiItem = this.onClickMultiItem.bind(this);
    this.onSelectSubItem = this.onSelectSubItem.bind(this);
    this.onChangeMultiItemQty = this.onChangeMultiItemQty.bind(this);
    this.onSelectItem = this.onSelectItem.bind(this);
    this.onSelectItemCategory = this.onSelectItemCategory.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);
    this.onChangeCustomItem = this.onChangeCustomItem.bind(this);
    this.onChangeCustomQuantity = this.onChangeCustomQuantity.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.onAddItem = this.onAddItem.bind(this);
    this.onCancelShowDuplicateModal = this.onCancelShowDuplicateModal.bind(this)

    // Confirm shipping adjustment
    this.onResetFee = this.onResetFee.bind(this);
    this.onHandleFeeAdjustModal = this.onHandleFeeAdjustModal.bind(this);
    this.checkingDuplicateItem = this.checkingDuplicateItem.bind(this)
  }

  handleOpenEditedModal(id) {
    this.setState({ edited_item: id });
  }

  handleCloseEditedModal() {
    this.setState({ edited_item: null });
  }

  handleOpenModalPreview() {
    this.setState({ modal_preview: true });
  }

  handleCloseModalPreview() {
    this.setState({ modal_preview: false });
  }

  componentDidMount() {
    this.checkingDuplicateItem(true)
  }

  componentDidUpdate() {
    if (this.props.check_duplicate) {
      this.checkingDuplicateItem()
    }
  }

  handleOpenMultiAddItemsModal() {
    let multi_items = [];
    let cate_ids = {};

    for (let elm of this.props.ItemSelect) {
      let item = elm;
      item.qty = 1;
      multi_items.push(item);
      cate_ids[elm.item_category_id] = {
        is_selected: false,
        is_hidden: false,
        name: elm.item_category.sys_name,
      };
    }

    this.setState({
      sub_items_selected: this.props.selectSubItemList,
      modal_multi_add: true,
      multi_items: multi_items,
      multi_item_cate_ids: cate_ids,
    });
  }

  handleCloseMultiAddItemsModal() {
    this.setState({
      modal_multi_add: false,
      multi_items: [],
      multi_item_cate_ids: {},
    });
  }

  onAddMultiItems() {
    let { multi_items } = this.state;
    let items = [];
    for (let item of multi_items) {
      if (item.is_selected) {
        items.push(item);
      }
    }

    this.props.onAddItems(items);
    this.handleCloseMultiAddItemsModal();

    this.setState({
      select_multi_item_vendor: 0,
      select_multi_item_dc: 0,
    });
  }

  onSearchMultiAddItems(e) {
    this.setState({ modal_multi_add_query: e.target.value });
  }

  onClickMultiItemCategory(item_category_id) {
    let { multi_item_cate_ids } = this.state;
    multi_item_cate_ids[item_category_id].is_hidden = !multi_item_cate_ids[item_category_id].is_hidden;
    this.setState({ multi_item_cate_ids: multi_item_cate_ids });
  }

  onClickMultiItemHideAll(hiding) {
    let { multi_item_cate_ids } = this.state;
    Object.keys(multi_item_cate_ids).forEach((key) => {
      multi_item_cate_ids[key].is_hidden = hiding;
    });
    this.setState({ multi_item_cate_ids: multi_item_cate_ids });
  }

  onClickMultiItemCate(e, item_category_id) {
    e.stopPropagation();
    let { multi_items, multi_item_cate_ids, sub_items_selected } = this.state;
    multi_item_cate_ids[item_category_id].is_selected = e.target.checked;

    const new_sub_items = [...sub_items_selected];

    let temp = multi_items.map((elm) => {
      if (elm.item_category_id === parseInt(item_category_id)) {
        elm.is_selected = e.target.checked;
        if (elm.is_parent) {
          if (!e.target.checked) {
            // const new_sub_items = [...sub_items_selected];

            elm?.parent_item_ids.forEach((id) => {
              const id_index = new_sub_items.findIndex((ns_item) => ns_item.parent_item_id === elm.id && ns_item.sub_item_id === id);
              if (id_index !== -1) new_sub_items.splice(id_index, 1);

              this.setState({ sub_items_selected: new_sub_items });
              this.props.onMatchSubItemList(new_sub_items);
            });
          } else {
            elm?.parent_item_ids.forEach((id) => {
              new_sub_items.push({
                parent_item_id: elm.id,
                sub_item_id: id,
              });
            });

            const sub_items = this.props.onMatchSubItemList(new_sub_items);

            this.setState({ sub_items_selected: sub_items });
          }
        }
      }
      return elm;
    });
    this.setState({
      multi_items: temp,
      multi_item_cate_ids: multi_item_cate_ids,
    });
  }

  onClickMultiItem(id, isDc = false, is_click_sub = false, new_select_sub_items, s_id) {
    let { multi_items, multi_item_cate_ids, select_multi_item_vendor, select_multi_item_dc, sub_items_selected } = this.state;
    let i_index = multi_items.findIndex((elm) => elm.id === id);
    let s_index = multi_items.findIndex((elm) => elm.id === s_id);

    let selectDc = select_multi_item_dc;
    let selectVendor = select_multi_item_vendor;

    if (i_index !== -1) {
      if (is_click_sub) {
        const find_sub_item = new_select_sub_items.filter((s_item) => multi_items[i_index]?.parent_item_ids?.find((id) => s_item.sub_item_id === id && s_item.parent_item_id === multi_items[i_index].id));
        multi_items[i_index].is_selected = find_sub_item.length > 0;

        if (!multi_items[i_index].is_selected) {
          if (isDc) {
            selectDc = selectDc - 1;
          } else {
            selectVendor = selectVendor - 1;
          }
          multi_item_cate_ids[multi_items[i_index].item_category_id].is_selected = false;
        } else {
          if (isDc) {
            selectDc = selectDc + 1;
          } else {
            selectVendor = selectVendor + 1;
          }
        }
      } else {
        multi_items[i_index].is_selected = !multi_items[i_index].is_selected;

        if (multi_items[i_index].is_selected) {
          if (multi_items[i_index].is_parent) {
            const sub_items = [
              ...sub_items_selected,
              ...multi_items[i_index]?.parent_item_ids.map((item_id) => {
                return {
                  sub_item_id: item_id,
                  parent_item_id: multi_items[i_index].id,
                };
              }),
            ];

            const remove_duplicate_items = sub_items.filter((item, i_index) => sub_items.findIndex((s_item) => s_item.parent_item_id === item.parent_item_id && s_item.sub_item_id === item.sub_item_id) === i_index);

            this.props.onMatchSubItemList(remove_duplicate_items);
            this.setState({ sub_items_selected: remove_duplicate_items });
          }
        } else {
          const { sub_items_selected } = this.state;

          let new_sub_items_selected = [...sub_items_selected];

          multi_items[i_index]?.parent_item_ids?.forEach((id) => {
            const s_index = new_sub_items_selected.findIndex((ns_item) => ns_item.parent_item_id === multi_items[i_index].id && ns_item.sub_item_id === id);
            if (s_index !== -1) new_sub_items_selected.splice(s_index, 1);
          });

          this.props.onMatchSubItemList(new_sub_items_selected);
          this.setState({ sub_items_selected: new_sub_items_selected });
        }

        if (!multi_items[i_index].is_selected) {
          if (isDc) {
            selectDc = selectDc - 1;
          } else {
            selectVendor = selectVendor - 1;
          }
          multi_item_cate_ids[multi_items[i_index].item_category_id].is_selected = false;
        } else {
          if (isDc) {
            selectDc = selectDc + 1;
          } else {
            selectVendor = selectVendor + 1;
          }
        }
      }
    }

    this.setState({
      select_multi_item_dc: selectDc,
      select_multi_item_vendor: selectVendor,
      multi_items: multi_items,
      multi_item_cate_ids: multi_item_cate_ids,
    });
  }

  onChangeMultiItemQty(e, id) {
    let { multi_items } = this.state;
    let i_index = multi_items.findIndex((elm) => elm.id === id);
    if (i_index >= 0) {
      multi_items[i_index].qty = e.target.value;
    }
    this.setState({ multi_items: multi_items });
  }

  onSelectItem(e) {
    this.setState({ selected_item: e.id });
  }

  onSelectItemCategory(e) {
    this.setState({ selected_item_category: e.id });
  }

  onChangeComment(e) {
    this.setState({ comment: e.target.value });
  }

  onChangeCustomItem(e) {
    this.setState({ custom_item: e.target.value });
  }

  onChangeCustomQuantity(e) {
    this.setState({ custom_quantity: e.target.value });
  }

  onChangeQuantity(e) {
    this.setState({ quantity: e.target.value });
  }

  onAddItem(new_item) {
    this.props.onAddItem(new_item.item, new_item.quantity, new_item.comment, new_item.is_mini_select);
    this.setState({ selected_item: "", quantity: "", comment: "" });
    this.handleCloseModalPreview();
  }

  /* Confirm shipping adjustment (start) */
  onHandleFeeAdjustModal(cate_id) {
    this.setState({ isConfirmingAdjustment: cate_id });
  }

  onResetFee() {
    this.props.onResetFee(this.state.isConfirmingAdjustment);
    this.onHandleFeeAdjustModal(false);
  }

  displayFeeAdjustmentModal() {
    let { isConfirmingAdjustment } = this.state;
    let { quotation_item_categories } = this.props.quotation;
    if (isConfirmingAdjustment !== false) {
      return (
        <ModalConfirmation
          isOpen={true}
          title="ปรับค่าบริการเป็นค่าเริ่มต้น"
          content={
            <p>
              ต้องการปรับค่าบริการของ
              <u>
                <strong>{itemCategoryName(quotation_item_categories[isConfirmingAdjustment])}</strong>
              </u>
              เป็นค่าเริ่มต้น ?
            </p>
          }
          onConfirm={this.onResetFee}
          onCancel={() => this.onHandleFeeAdjustModal(false)}
        />
      );
    }
  }
  /* Confirm shipping adjustment (end) */

  inputField(elm, field, disabled, isNumber) {
    if (isNumber === true) {
      return <Number innerClass="text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeItemDetails(e, elm.id)} disabled={disabled} />;
    } else {
      return <input className="form-control text-right" name={field} value={elm[field]} onChange={(e) => this.props.onChangeItemDetails(e, elm.id)} disabled={this.props.disabled}></input>;
    }
  }

  addItemMd() {
    let { disabled, quotation } = this.props;
    if (!disabled) {
      let class_text = quotation.quotation_items === 0 ? "mr-1" : "btn-sm";

      return (
        <div className="quo-btn-add-item-wrapper">
          <button type="button" className={`plus-button large quo-btn-add-item d-lg-none mt-3 ${class_text}`} onClick={this.handleOpenModalPreview}>
            +
          </button>
        </div>
      );
    }
  }

  adddMultiItemsModal() {
    let { modal_multi_add, select_multi_item_dc, select_multi_item_vendor, sub_items_selected } = this.state;
    let { quotation, filterItemSelect } = this.props;
    return (
      <QuotationFormItemModal
        quotation={quotation}
        filterItemSelect={filterItemSelect}
        isOpen={modal_multi_add}
        onSelectSubItem={this.onSelectSubItem}
        sub_items_selected={sub_items_selected}
        handleCloseMultiAddItemsModal={this.handleCloseMultiAddItemsModal}
        onSearchMultiAddItems={this.onSearchMultiAddItems}
        onClickMultiItemHideAll={this.onClickMultiItemHideAll}
        onClickMultiItemCategory={this.onClickMultiItemCategory}
        onClickMultiItemCate={this.onClickMultiItemCate}
        onClickMultiItem={this.onClickMultiItem}
        onAddMultiItems={this.onAddMultiItems}
        select_multi_item_dc={select_multi_item_dc}
        select_multi_item_vendor={select_multi_item_vendor}
        {...this.state}
      />
    );
  }

  onDeleteItemQuo(item) {
    const { sub_items_selected, item_duplicate_list } = this.state;
    let new_sub_items_selected = [...sub_items_selected];
    let new_item_duplicate_list = [...item_duplicate_list];
    const find_index = new_sub_items_selected.findIndex((s_item) => s_item.sub_item_id === item.item_id);
    const find_duplicate_index = new_item_duplicate_list.findIndex((d_item) => d_item.item_id === item.item_id)

    if (find_duplicate_index > -1) new_item_duplicate_list.splice(find_duplicate_index, 1)
    if (find_index > -1) new_sub_items_selected.splice(find_index, 1);

    this.setState({ sub_items_selected: new_sub_items_selected, item_duplicate_list: new_item_duplicate_list });
    this.props.onDeleteItem(item);
  }

  onSelectSubItem(id, parent_id) {
    const { sub_items_selected } = this.state;
    let new_select_sub_items = [...sub_items_selected];

    const index = new_select_sub_items.findIndex((s_item) => s_item.sub_item_id === id && s_item.parent_item_id === parent_id);

    if (index !== -1) new_select_sub_items.splice(index, 1);
    else new_select_sub_items.push({ parent_item_id: parent_id, sub_item_id: id });

    this.props.onMatchSubItemList(new_select_sub_items);
    this.onClickMultiItem(parent_id, false, true, new_select_sub_items, id);

    this.setState({ sub_items_selected: new_select_sub_items });
  }

  editModal(elm) {
    let { edited_item } = this.state;
    return (
      <Modal isOpen={elm.id === edited_item} contentLabel="onAddItem" onRequestClose={this.handleCloseEditedModal} style={MODAL_STYLE_SMALL_SM}>
        <div className="d-flex flex-column">
          <h5>แก้ไขรายการสินค้า</h5>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รายการ</label>
            </div>
            <div className="d-flex-2">{elm.name}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>จำนวน</label>
            </div>
            <div className="d-flex-2">{this.inputField(elm, "qty", !objectIsEmpty(elm.warranty_codes), true)}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>หน่วย</label>
            </div>
            <div className="d-flex-2">{elm.unit}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>ราคา</label>
            </div>
            <div className="d-flex-2">
              {this.inputField(elm, "price", this.props.disabled, true)}
              {/* {elm.price.toLocaleString()} */}
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>ยอดอหัก 3%</label>
            </div>
            <div className="d-flex-2">
              {this.inputField(elm, "wht", this.props.disabled, true)}
              {/* {elm.price.toLocaleString()} */}
            </div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>รวม</label>
            </div>
            <div className="d-flex-2">{(elm.price * elm.qty).toLocaleString()}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="d-flex-1">
              <label>comment</label>
            </div>
            <div className="d-flex-2">{this.inputField(elm, "note", this.props.disabled)}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <button type="button" className="btn btn-success btn-sm w-100" onClick={this.handleCloseEditedModal} disabled={this.props.disabled}>
              บันทึก
            </button>
            <button type="button" className="btn btn-danger btn-sm w-100 ml-1" onClick={() => this.onDeleteItemQuo(elm)} disabled={this.props.disabled}>
              ลบ
            </button>
            <button type="button" className="btn btn-secondary btn-sm w-100 ml-1" onClick={this.handleCloseEditedModal}>
              ยกเลิก
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  addModal() {
    return (
      <Modal isOpen={this.state.modal_preview} contentLabel="onAddItem" onRequestClose={this.handleCloseModalPreview} style={MODAL_STYLE_SMALL_SM}>
        <div className="d-flex flex-column">{this.addItemForm()}</div>
      </Modal>
    );
  }

  displayItems(itemsByCate, index_offset) {
    let { is_completed } = this.state;
    let item_index = -1;
    let items = itemsByCate.map((elm, index) => {
      if (elm.from_parent || (elm.is_parent && elm?.parent_items?.length === 0)) return false;

      item_index = item_index + 1;

      const deleteBtn = (item) => {
        if (!this.props.disabled) {
          return (
            <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => this.onDeleteItemQuo(item)}>
              X
            </button>
          );
        }
      };

      let price_with_zone = 0
      let net_cost = 0

      let find_parent_items = itemsByCate.filter((item) => item.from_parent_item_id === elm.item_id);

      if (elm.is_parent) {
        find_parent_items.map((item) => {
          price_with_zone = this.props.zone_type ? (this.props.zone_type.name === "ส่วนกลาง" ? item.price : item.regional_price ? item.regional_price : item.price) : item.price;
          net_cost = net_cost + Multiple(price_with_zone, item.qty);

          return net_cost
        })
      }
      else {
        price_with_zone = elm.is_parent ? elm.parent_price : this.props.zone_type ? (this.props.zone_type.name === "ส่วนกลาง" ? elm.price : elm.regional_price ? elm.regional_price : elm.price) : elm.price;
        net_cost = Multiple(price_with_zone, elm.qty);
      }


      let code_name = elm.code + " - " + elm.name;
      let item_canceled = is_completed && !elm.issued;
      // let find_parent_items = itemsByCate.filter((item) => item.from_parent_item_id === elm.item_id);

      return (
        <React.Fragment key={elm.id}>
          {/* md up */}
          {elm.is_parent ? (
            <div style={{ paddingBottom: 5, backgroundColor: "white", marginTop: 20 }} className="border display-item-container-main">
              <div className={`display-item-container border ${item_canceled ? "text-line-through" : ""}`} style={{ marginTop: -10 }}>
                <div style={{ textAlign: "center" }}>{item_index + index_offset}</div>
                <div style={{ textAlign: "left" }}>{code_name}</div>
                <div style={{ textAlign: "right" }}>{elm.qty}</div>
                <div>{elm.unit}</div>
                <div className="text-right">-</div>
                <div className="text-right">-</div>
                <div style={{ textAlign: "right" }}>
                  <div>{net_cost.toLocaleString()}</div>
                </div>
                <div style={{ paddingLeft: 5, textAlign: "right" }}>{this.inputField(elm, "note", this.props.disabled)}</div>
              </div>
              {find_parent_items?.map((item, i_index) => {
                let sub_item_price_with_zone = this.props.zone_type ? (this.props.zone_type.name === "ส่วนกลาง" ? item.price : item.regional_price ? item.regional_price : item.price) : item.price;
                let sub_item_net_cost = Multiple(sub_item_price_with_zone, item.qty);

                return (
                  <div style={{ paddingLeft: -15, paddingRight: -15 }} className={`display-sub-item-container ${item_canceled ? "text-line-through" : ""}`}>
                    <div style={{ textAlign: "left" }}>
                      {item_index + index_offset}.{i_index + 1}
                    </div>
                    {/* <div style={{ textAlign: "left" }}>{item.name}</div> */}
                    <div style={{ textAlign: "left", display: "flex", gap: 5, alignItems: 'center' }}>
                      {item.is_duplicate && <BlockOutlined style={{ fontSize: 24, color: "#007bff", cursor: "pointer" }} onClick={() => this.onCancelShowDuplicateModal(true)} />}
                      <div style={{ color: item.is_duplicate ? "#007bff" : "" }}>{item.name}</div>
                    </div>
                    <div style={{ marginLeft: -15 }}>{this.inputField(item, "qty", !objectIsEmpty(item.warranty_codes) || this.props.disabled, true)}</div>
                    <div style={{ marginLeft: -15 }}>{item.unit}</div>
                    <div style={{ marginLeft: -10 }}>
                      {this.props.zone_type
                        ? this.props.zone_type.name === "ส่วนกลาง"
                          ? this.inputField(item, "price", this.props.disabled, true)
                          : item.regional_price
                            ? this.inputField(item, "regional_price", this.props.disabled, true)
                            : this.inputField(item, "price", this.props.disabled, true)
                        : this.inputField(item, "price", this.props.disabled, true)}
                    </div>
                    <div>{this.inputField(item, "wht", this.props.disabled, true)}</div>
                    <div style={{ textAlign: "right" }}>
                      <div className="w-100 text-right">{sub_item_net_cost.toLocaleString()}</div>
                    </div>
                    <div style={{ textAlign: "right", display: "flex", marginLeft: 8 }}>
                      {this.inputField(item, "note", this.props.disabled)}
                      {deleteBtn(item)}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={`display-item-container border ${item_canceled ? "text-line-through" : ""}`}>
              <div style={{ alignSelf: "center" }}>{index_offset + item_index}</div>
              <div style={{ textAlign: "left", display: "flex", gap: 5, alignItems: 'center' }}>{elm.is_duplicate && <BlockOutlined style={{ fontSize: 24, color: "#007bff", cursor: "pointer" }} onClick={() => this.onCancelShowDuplicateModal(true)} />}<div style={{ color: elm.is_duplicate ? "#007bff" : "" }}>{code_name}</div></div>
              <div>{this.inputField(elm, "qty", !objectIsEmpty(elm.warranty_codes) || this.props.disabled, true)}</div>
              <div>{elm.unit}</div>
              <div>
                {this.props.zone_type
                  ? this.props.zone_type.name === "ส่วนกลาง"
                    ? this.inputField(elm, "price", this.props.disabled, true)
                    : elm.regional_price
                      ? this.inputField(elm, "regional_price", this.props.disabled, true)
                      : this.inputField(elm, "price", this.props.disabled, true)
                  : this.inputField(elm, "price", this.props.disabled, true)}
              </div>
              <div>{this.inputField(elm, "wht", this.props.disabled, true)}</div>
              <div style={{ textAlign: "right" }}>
                <div>{net_cost.toLocaleString()}</div>
              </div>
              <div style={{ display: "flex", textAlign: "right" }}>
                {this.inputField(elm, "note", this.props.disabled)}
                {deleteBtn(elm)}
              </div>
            </div>
          )}
          {/* md down */}
          <a onClick={() => (this.props.disabled ? false : this.handleOpenEditedModal(elm.id))}>
            <div className={`row mt-2 border d-md-none ${item_canceled ? "text-line-through" : ""}`}>
              <div className="table-item-bg col-12 pt-1 col-md-4 pr-3">{code_name}</div>
              <div className="table-item-bg col col-md-1 text-center d-md-none">
                จำนวน {elm.qty} {elm.unit}
              </div>
              <div className="table-item-bg col-12 col-md d-flex justify-content-end bg-ghostwhite d-md-none">
                <h4 className="sign-baht color-darkgray#007bff my-0">{net_cost.toLocaleString()}</h4>
              </div>
            </div>
          </a>
          {this.editModal(elm)}
        </React.Fragment>
      );
    });

    return items;
  }

  checkDupExpense(quotation_item_categories) {
    let all_dup_expenses = [];
    let dup_ids = {};
    Object.keys(quotation_item_categories).forEach((key) => {
      let cate_ori_name = quotation_item_categories[key].ori_name;
      quotation_item_categories[key].item_category_expenses.forEach((elm) => {
        all_dup_expenses.push({
          id: elm.id,
          name: elm.name,
          ori_name: cate_ori_name,
        });
      });
    });

    let nameByOriName = _.groupBy(all_dup_expenses, function (elm) {
      return `${elm.ori_name}_${elm.name}`;
    });

    for (let key in nameByOriName) {
      if (nameByOriName[key].length > 1) {
        for (let exp of nameByOriName[key]) {
          dup_ids[exp.id] = true;
        }
      }
    }
    return dup_ids;
  }

  displayItemByCategory(isDC) {
    let { quotation } = this.props;
    let { quotation_item_categories, quotation_items } = quotation;

    let index_offset = 1;
    let vendorItemsByCategory = _.groupBy(quotation_items, function (value) {
      return value.item_category_id;
    });

    // https://trello.com/c/nhhgGcFL
    let all_dup_expenses = {};
    if (quotation) {
      all_dup_expenses = this.checkDupExpense(quotation_item_categories);
    }

    let result = Object.keys(vendorItemsByCategory).map((key) => {
      let dcId = quotation_item_categories[key].dc_id !== undefined ? quotation_item_categories[key].dc_id : quotation_item_categories[key].item_category.dc_id;

      if (dcId === 0 && isDC) return false;
      else if (dcId === 1 && !isDC) return false;

      let current_cate = quotation_item_categories[key];
      let price_sum = 0;

      let rowItems = this.displayItems(vendorItemsByCategory[key], index_offset);
      vendorItemsByCategory[key].forEach((temp) => {
        if (temp.is_parent) return;

        let price_with_zone = this.props.zone_type ? (this.props.zone_type.name === "ส่วนกลาง" ? temp.price : temp.regional_price ? temp.regional_price : temp.price) : temp.price;
        let price = parseFloat(price_with_zone);
        let qty = parseFloat(temp.qty);
        price_sum += Multiple(qty, price);
      });

      const remove_item_offset = vendorItemsByCategory[key].filter(({ from_parent }) => from_parent);
      index_offset += vendorItemsByCategory[key].length - remove_item_offset.length;

      // const is_type_7 = current_cate.item_category_expenses.find((cate) => cate.method_type === 7 && cate.qty > cate.from_value);

      let is_type_7 = null;

      current_cate.item_category_expenses.forEach((cate) => {
        if (cate.method_type === 7 && cate.qty > cate.from_value) {
          if (is_type_7) {
            if (cate.qty > is_type_7.from_value && is_type_7.from_value < cate.from_value) {
              is_type_7 = cate;
            }
          } else {
            is_type_7 = cate;
          }
        }
      });

      let sum_expense = getCategoryTotalExpense(current_cate.item_category_expenses, false, is_type_7 ? is_type_7 : false);
      let item_qty_arr = vendorItemsByCategory[key].map((item) => {
        return parseInt(item.qty) || 0;
      });

      let item_price_arr = vendorItemsByCategory[key].map((item) => {
        return parseInt(item.price) || 0;
      });

      let suggest_fee = solveFeeNew(sum_expense, item_qty_arr, item_price_arr);
      let suggest_value = suggest_fee[3] - sum_expense;
      let tooltipText = `ค่าบริการหลังหักส่วนลด ${sum_expense.toLocaleString()} </br>
                         suggestion :  "${suggest_value > 0 ? "เพิ่ม" : "ลด"} ${suggest_value.toLocaleString()} บาท เป็น ${suggest_fee[3].toLocaleString()}"`;

      const mockShippingCost = (style) => {
        if (sum_expense === suggest_fee[3]) {
          return "";
        } else {
          return <TooltipExclamationMark text={tooltipText} innerStyle={style} />;
        }
      };

      return (
        <React.Fragment key={key}>
          <div className="row">
            <div className="table-item-bg category-title first col-1 col-p5 p-0" style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}>
              <i className={`icon-adjust cursor-pointer ${this.props.disabled ? "d-none" : ""}`} onClick={() => this.onHandleFeeAdjustModal(key)} />
            </div>
            <div className="table-item-bg category-title font-weight-bold col p-0 col-md-5">
              <input className="form-control text-left col-ellipsis" name="quotation_category_sys_name" value={quotation_item_categories[key].sys_name} onChange={(e) => this.props.onChangeCategoryName(e, key)} disabled={this.props.disabled} />
              {mockShippingCost("d-md-none")}
            </div>
            <div className="table-item-bg category-title col p-md-0 justify-content-end d-none d-md-flex">
              <span className="sign-baht pl-1 font-weight-bold">{`ค่าบริการ ${sum_expense.toLocaleString()}`}</span> {mockShippingCost()}
            </div>
            <div className="table-item-bg category-title font-weight-bold col col-md-3 justify-content-end sign-baht" style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5 }}>
              <span className="d-none d-md-block pr-1">รวม</span> {(price_sum + sum_expense).toLocaleString()}
            </div>
          </div>
          <div className="display-item-container border" style={{ marginBottom: 10 }}>
            <div>
              <strong>ลำดับ</strong>
            </div>
            <div style={{ textAlign: "left" }}>
              <strong>รายการสินค้า </strong>
            </div>
            <div>
              <strong>จำนวน</strong>
            </div>
            <div>
              <strong>หน่วย</strong>
            </div>
            <div className="text-right">
              <strong>ราคา</strong>
            </div>
            <div className="text-right" style={{ whiteSpace: "nowrap" }}>
              <strong>ยอดหัก 3%</strong>
            </div>
            <div style={{ textAlign: "right" }}>
              <strong>รวม</strong>
            </div>
            <div style={{ textAlign: "right" }}>
              <strong>comment</strong>
            </div>
          </div>
          {rowItems}
          <QuotationFormItemsShippingCost {...this.props} quotationItemCategories={quotation_item_categories[key]} costByCate={current_cate.item_category_expenses} all_dup_expenses={all_dup_expenses} is_completed={this.state.is_completed} items_in_cate={vendorItemsByCategory[key]} />
          <div className="row mt-3"></div>
        </React.Fragment>
      );
    });

    return result;
  }

  onChangeItemListTab(index) {
    this.setState({ current_item_list_tab: index });
  }

  displayItemListTabs(item_list_text, item_list_tab_name) {
    let { current_item_list_tab } = this.state;
    return (
      <li className="nav-item">
        <a className={`nav-link px-2 ${current_item_list_tab === QUO_ITEM_LIST_TABS[item_list_tab_name] ? "active" : ""}`} onClick={() => this.onChangeItemListTab(QUO_ITEM_LIST_TABS[item_list_tab_name])}>
          {item_list_text}
        </a>
      </li>
    );
  }

  displayVendorItemList() {
    let tableHeight = "";
    return (
      <React.Fragment>
        <div className="col-12">
          <div className={`row quo-table ${tableHeight}`}>
            <div className="col-12">{this.displayItemByCategory(false)}</div>
          </div>
          {this.displaySummary(true)}
        </div>
      </React.Fragment>
    );
  }

  displayDcItemList() {
    let tableHeight = "";
    return (
      <React.Fragment>
        <div className="col-12">
          <div className={`row quo-table ${tableHeight}`}>
            <div className="col-12">{this.displayItemByCategory(true)}</div>
          </div>
          {this.displaySummary(true)}
        </div>
      </React.Fragment>
    );
  }

  addItemForm() {
    let { ItemSelect, disabled } = this.props;
    if (disabled !== true) {
      return (
        <React.Fragment>
          <div className="card-box mt-0 p-3">
            <AddItem ItemSelect={ItemSelect} disabled={disabled} onAddItem={this.onAddItem} handleCloseModalPreview={this.handleCloseModalPreview} handleOpenMultiAddItemsModal={this.handleOpenMultiAddItemsModal} />
          </div>
        </React.Fragment>
      );
    }
  }

  displaySummary(md) {
    let { quotation } = this.props;
    let total_with_discount = calTotalSales(quotation);
    let discount = calTotalDiscount(quotation);
    let total = total_with_discount + discount;
    let vat = total_with_discount * 0.07;

    let text_total = "รวมเป็นเงิน";
    let text_discount = "ส่วนลด";
    let text_total_with_discount = "หลังหักส่วนลด";
    let text_vat = "VAT 7%";
    let text_sum = "รวมทั้งสิ้น";
    let witholding_tax = "ยอดหัก ณ ที่จ่าย 3%";
    if (md) {
      return (
        <React.Fragment>
          {/* lg down */}
          <div className="mt-2 d-lg-none">
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">{text_total}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{total.toLocaleString()}</div>
            </div>
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">{text_discount}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{discount.toLocaleString()}</div>
            </div>
            <div className="row">
              <div className="table-item-bg col p-0 justify-content-end">{text_total_with_discount}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{total_with_discount.toLocaleString()}</div>
            </div>
            <div className="row mb-2">
              <div className="table-item-bg col p-0 justify-content-end">{text_vat}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{vat.toLocaleString()}</div>
            </div>
            <div className="row mb-2">
              <div className="table-item-bg col p-0 justify-content-end">{witholding_tax}</div>
              <div className="table-item-bg col justify-content-end sign-baht">{<Number innerClass="text-right" name={"witholding_tax"} value={this.props.witholding_tax || 0} onChange={(e) => this.props.changeWithOutHoldingTax(e)} disabled={this.props.disabled} />}</div>
            </div>
            <div className="row m-b-100">
              <div className="table-item-bg total col p-0 justify-content-end">{text_sum}</div>
              <div className="table-item-bg total col justify-content-end sign-baht">{calTotalSalesWithVat(this.props.quotation, this.props.witholding_tax).toLocaleString()}</div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {/* lg up */}
          <div className="mt-2 d-none d-lg-block">
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_total}</div>
              <div className="d-flex-1 py-1 text-right">{total.toLocaleString()}</div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_discount}</div>
              <div className="d-flex-1 py-1 text-right">{discount.toLocaleString()}</div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_total_with_discount}</div>
              <div className="d-flex-1 py-1 text-right">{total_with_discount.toLocaleString()}</div>
            </div>
            <div className="d-flex flex-row pr-3">
              <div className="d-flex-1 py-1 text-right">{text_vat}</div>
              <div className="d-flex-1 py-1 text-right">{vat.toLocaleString()}</div>
            </div>
            <div className="flex-row pr-3" style={{ alignItems: "center", display: "grid", gridTemplateColumns: "50% 50%" }}>
              <div className="d-flex-1 py-1 text-right">{witholding_tax}</div>
              <div className="d-flex-1 py-1 text-right witholding-tax">{<Number innerClass="text-right" name={"witholding_tax"} value={this.props.witholding_tax || 0} onChange={(e) => this.props.changeWithOutHoldingTax(e)} disabled={this.props.disabled} />}</div>
            </div>
            <div className="d-flex flex-row pr-3 bg-grayyellow">
              <div className="d-flex-1 py-2 text-right font-weight-bold">{text_sum}</div>
              <div className="d-flex-1 py-2 text-right font-weight-bold">{(total_with_discount + vat - this.props.witholding_tax).toLocaleString()}</div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  rightSideBar() {
    return (
      <React.Fragment>
        <div className="quo-right-side-bar">
          {this.addItemForm()}
          {this.displaySummary()}
        </div>
      </React.Fragment>
    );
  }

  checkingDuplicateItem(is_first = false) {
    let is_duplicate = false;
    let duplicate_list = [...this.state.item_duplicate_list];

    this.props.quotation.quotation_items.forEach((item, index) => {
      const find_duplicate_list = duplicate_list.find((d_item) => item.item_id === d_item.item_id)

      if (item.is_duplicate && !item.is_parent && !find_duplicate_list) {
        is_duplicate = true;
        duplicate_list.push(item);
      }

      if (this.props.quotation.quotation_items.length - 1 === index) {
        if (!is_first) this.setState({ is_show_duplicate_modal: is_duplicate })
        this.props.setCheckDuplicateItem(false)
      }

      this.setState({ item_duplicate_list: duplicate_list });
    });
  }

  onCancelShowDuplicateModal(stage) {
    this.setState({ is_show_duplicate_modal: stage })
  }

  modalDuplicate() {
    return <QuotationModalDuplicate onCancelShowDuplicateModal={this.onCancelShowDuplicateModal} isOpen={this.state.is_show_duplicate_modal} itemDuplicateList={this.state.item_duplicate_list} />
  }

  render() {

    return (
      <React.Fragment>
        {this.addItemMd()}
        <div className="col quo-scrollable-table">
          <div className="row p-0 p-md-4">
            <div className="col pr-lg-0">
              <div className="d-flex mb-2 item-vendor-and-dc-tabs">
                <ul className="nav nav-tabs tab-responsive">
                  {this.displayItemListTabs(`สินค้า Vendor (${this.props.selected_vendor_item})`, "vendor")}
                  {this.displayItemListTabs(`สินค้า DC (${this.props.selected_dc_item})`, "dc")}
                </ul>
                {this.state.current_item_list_tab === 1 && this.props.zone_type && (
                  <div className="use-price-text">
                    <span>ค่าเริ่มต้นของราคาขายสินค้า DC ในใบเสนอราคานี้คือ :</span>
                    <span className="use-price-type">{this?.props?.zone_type?.name}</span>
                  </div>
                )}
                {!this.props.disabled && (
                  <div onClick={this.props.onSelectExistingAddTemplate} className="select-from-template-btn">
                    <PlusOutlined /> เลือกจาก Template
                  </div>
                )}
              </div>
              {this.state.current_item_list_tab === QUO_ITEM_LIST_TABS.vendor ? this.displayVendorItemList() : this.displayDcItemList()}
            </div>
            <div className="col-3 d-none d-lg-block">{this.rightSideBar()}</div>
          </div>
        </div>
        {this.modalDuplicate()}
        {this.addModal()}
        {this.adddMultiItemsModal()}
        {this.displayFeeAdjustmentModal()}
      </React.Fragment>
    );
  }
}
